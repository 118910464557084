import { connect } from 'react-redux';
import { getAuctionPreviewLink } from 'common/links';
import AuctionsManagementAddBasedOnAuctionPage from './AuctionsManagementAddBasedOnAuctionPage';
import { addAndPublishAsync, addAsync } from './actions';
import {
  commonMapStateToProps,
  commonMapDispatchToProps,
  commonMergeProps,
} from './commonAuctionsContainer';

const mapStateToProps = state => {
  return {
    ...commonMapStateToProps(state),
    routes: state.dictionary.static.routes,
    zipCodes: state.dictionary.static.zipCodes,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: model =>
      addAndPublishAsync(model).then(ret =>
        ownProps.history.push(getAuctionPreviewLink(ret.data)),
      ),
    onSubmitSave: model =>
      addAsync(model).then(ret =>
        ownProps.history.push(getAuctionPreviewLink(ret.data)),
      ),
    ...commonMapDispatchToProps(dispatch),
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  return {
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,
    ...commonMergeProps(propsFromState, propsFromDispatch, ownProps),
  };
};

const AuctionsManagementAddBasedOnAuctionPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AuctionsManagementAddBasedOnAuctionPage);

export default AuctionsManagementAddBasedOnAuctionPageContainer;
