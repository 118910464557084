"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _FooterModule = _interopRequireDefault(require("./Footer.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {};
var defaultProps = {};

function Footer() {
  return _react.default.createElement("div", {
    className: _FooterModule.default.footer
  }, _react.default.createElement("div", {
    className: "".concat(_FooterModule.default.column, " ").concat(_FooterModule.default.left)
  }, _react.default.createElement("div", {
    className: _FooterModule.default.content
  }, _react.default.createElement("div", {
    className: _FooterModule.default.content_line
  }, "FoxTMS"), _react.default.createElement("div", {
    className: _FooterModule.default.content_line
  }, "Build 52927 Thu, Jan 23, 2019, 02:07:49 PM UTC"))), _react.default.createElement("div", {
    className: "".concat(_FooterModule.default.column, " ").concat(_FooterModule.default.center)
  }, _react.default.createElement("div", {
    className: _FooterModule.default.content
  }, "Copyright \xA9 2019 - 2020", ' ', _react.default.createElement("a", {
    href: "https://infox.pl/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "INFOX"))), _react.default.createElement("div", {
    className: "".concat(_FooterModule.default.column, " ").concat(_FooterModule.default.right)
  }));
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;
var _default = Footer;
exports.default = _default;