import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { reduxForm, FormSection, getFormValues } from 'redux-form';
import {
  scrollToTheFirstError,
  displayCompletionToast,
} from 'hh-shared/dist/consts/reduxFormMethods';
import FontAwesomeIcon from 'hh-shared/dist/components/commons/FontAwesomeIcon/FontAwesomeIcon';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import { onChangeZipCodeFactory } from 'common/zipCodeDistanceCalculator';
import NewAddressForm from 'commons/NewAddressForm';
import Form from 'fox-react/dist/components/Form';
import FoxButton from 'fox-react/dist/components/FoxButton';
import FoxComboBoxField from 'fox-react/dist/reduxFormFields/FoxComboBoxField';
import FoxDateTimePickerField from 'fox-react/dist/reduxFormFields/FoxDateTimePickerField';
import FoxInputNumberDecimalField from 'fox-react/dist/reduxFormFields/FoxInputNumberDecimalField';
import FoxInputNumberField from 'fox-react/dist/reduxFormFields/FoxInputNumberField';
import FoxInputTextareaField from 'fox-react/dist/reduxFormFields/FoxInputTextareaField';
import FoxMultiSelectField from 'fox-react/dist/reduxFormFields/FoxMultiSelectField';
import FoxRadioButtonsField from 'fox-react/dist/reduxFormFields/FoxRadioButtonsField';
import FoxSwitchField from 'fox-react/dist/reduxFormFields/FoxSwitchField';
import { fieldChanged } from 'fox-react/dist/utils/formHelpers';
import { isNotPastDateField } from 'fox-react/dist/utils/formValidators';
import ContentRevealer from 'hh-shared/dist/components/commons/ContentRevealer';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import icons from 'hh-shared/dist/consts/icons';
import lang from 'hh-shared/dist/language/services/languageService';
import CurrencyPriceInputWrapper from 'layout/CurrencyPriceInputWrapper';
import FoxConfirmationButton from 'fox-react/dist/components/FoxConfirmationButton';
import { maxLenght200 } from 'fox-react/dist/utils/formFieldNormalizers';
import FixDateTimePickerSubForm from 'commons/FixDateTimePickerSubForm';
import moment from 'moment';
import { isRequiredFieldValidator } from 'hh-shared/dist/utils/formValidators';
import phoneMask from 'hh-shared/dist/utils/phoneMask';
import FoxMaskedInputTextField from 'fox-react/dist/reduxFormFields/FoxMaskedInputTextField';

import AuctionsManagementSrv from '../AuctionsManagementSrv';
import { onChangeAddresses } from '../commonAuctionsContainer';
import { validate } from './validate';

const fnames = fieldNames.auctionInformationForm;

const isNotPastValidator = isNotPastDateField(
  lang.validationMessages.CannotBePastDate(),
);

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  showSubjects: PropTypes.bool.isRequired,
  facilities: PropTypes.array.isRequired,
  loadingMethods: PropTypes.array.isRequired,
  unloadingMethods: PropTypes.array.isRequired,
  auctionTypes: PropTypes.array.isRequired,
  auctionSubjects: PropTypes.array.isRequired,
  principalPersons: PropTypes.array.isRequired,
  addresses: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  onSubjectsChange: PropTypes.func.isRequired,
  onDistanceChange: PropTypes.func.isRequired,
  onPricePerKilometerChange: PropTypes.func.isRequired,
  onStartPriceChange: PropTypes.func.isRequired,
  onResetPrices: PropTypes.func.isRequired,
  auctionId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  isDraft: PropTypes.bool.isRequired,
  onPublish: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

function AuctionInformationEditForm({
  handleSubmit,
  submitting,
  showSubjects,
  facilities,
  loadingMethods,
  unloadingMethods,
  auctionTypes,
  auctionSubjects,
  addresses,
  principalPersons,
  currencies,
  onSubjectsChange,
  onDistanceChange,
  onPricePerKilometerChange,
  onStartPriceChange,
  onResetPrices,
  auctionId,
  history,
  isDraft,
  onPublish,
  onRemove,
}) {
  const { authorizedPickupPerson } = useSelector(state =>
    getFormValues(formNames.auctionInformationForm)(state),
  );

  const onCancelClick = () => {
    if (isDraft) {
      history.goBack();
    } else {
      AuctionsManagementSrv.cancel(auctionId).then(history.goBack);
    }
  };

  const { isNewLoadingAddress, isNewUnloadingAddress } = useSelector(state =>
    getFormValues(formNames.auctionInformationForm)(state),
  );

  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.AuctionInformation()}
            titleIcon={icons.auction}
          >
            <FoxRadioButtonsField
              label={lang.labels.AuctionType()}
              name={fnames.auctionType}
              items={auctionTypes}
              required
            />

            {showSubjects && (
              <FoxMultiSelectField
                label={lang.labels.Subjects()}
                name={fnames.subjects}
                items={auctionSubjects}
                required
                onChange={onSubjectsChange}
                validate={isRequiredFieldValidator}
              />
            )}

            <FoxDateTimePickerField
              label={lang.labels.StartDateTime()}
              placeholder={lang.labels.StartDateTime()}
              name={fnames.startTime}
              validate={isNotPastValidator}
              required
            />

            <FoxDateTimePickerField
              label={lang.labels.EndDateTime()}
              placeholder={lang.labels.EndDateTime()}
              name={fnames.endTime}
              required
            />

            <FoxComboBoxField
              label={lang.labels.PrincipalPerson()}
              placeholder={lang.labels.PrincipalPerson()}
              name={fnames.principalPerson}
              items={principalPersons}
              required
            />

            <FoxComboBoxField
              label={lang.labels.Facility()}
              placeholder={lang.labels.Facility()}
              name={fnames.facility}
              items={facilities}
              required
            />
          </FormSectionCard>
        </SectionWrapper>

        <SectionWrapper columnOnBreak>
          <FormSectionCard
            title={lang.labels.LoadingData()}
            titleIcon={icons.loadingVehicle}
            lessPaddingRight
          >
            <FixDateTimePickerSubForm
              label={lang.labels.LoadingDateTime()}
              placeholder={lang.labels.LoadingDateTime()}
              timeFromName={fnames.loadingTimeFrom}
              timeToName={fnames.loadingTimeTo}
              dataFixName={fnames.loadingTimeFix}
            />

            <ContentRevealer
              isVisible={!isNewLoadingAddress}
              visibleClassName="vertical-offset"
            >
              <FoxComboBoxField
                label={lang.labels.LoadingAddress()}
                name={fnames.loadingAddress}
                items={addresses}
                required
              />
            </ContentRevealer>

            <FoxSwitchField
              label={lang.labels.NewAddress()}
              placeholder={lang.labels.NewAddress()}
              name={fnames.isNewLoadingAddress}
            />

            <ContentRevealer isVisible={isNewLoadingAddress}>
              <FormSection name="newLoadingAddress">
                <NewAddressForm />
              </FormSection>
            </ContentRevealer>
          </FormSectionCard>
          <div className="column justify-content justify-content-center align-items align-items-center">
            <FontAwesomeIcon
              icon={icons.arrow}
              size="4x"
              className="triple-column-icon"
            />
          </div>
          <FormSectionCard
            title={lang.labels.UnloadingData()}
            titleIcon={icons.loadingVehicle}
            lessPaddingRight
          >
            <FixDateTimePickerSubForm
              label={lang.labels.UnloadingDateTime()}
              placeholder={lang.labels.UnloadingDateTime()}
              timeFromName={fnames.unloadingTimeFrom}
              timeToName={fnames.unloadingTimeTo}
              dataFixName={fnames.unloadingTimeFix}
            />

            <ContentRevealer
              isVisible={!isNewUnloadingAddress}
              visibleClassName="vertical-offset"
            >
              <FoxComboBoxField
                label={lang.labels.UnloadingAddress()}
                name={fnames.unloadingAddress}
                items={addresses}
                required
              />
            </ContentRevealer>

            <FoxSwitchField
              label={lang.labels.NewAddress()}
              placeholder={lang.labels.NewAddress()}
              name={fnames.isNewUnloadingAddress}
            />

            <ContentRevealer isVisible={isNewUnloadingAddress}>
              <FormSection name="newUnloadingAddress">
                <NewAddressForm />
              </FormSection>
            </ContentRevealer>

            <FoxInputTextareaField
              label={lang.labels.AuthorizedPickupPerson()}
              placeholder={lang.labels.AuthorizedPickupPerson()}
              name={fnames.authorizedPickupPerson}
              className="not-extendable"
              normalize={maxLenght200}
            />

            <ContentRevealer isVisible={!!authorizedPickupPerson}>
              <FoxMaskedInputTextField
                {...phoneMask}
                label={lang.labels.AuthorizedPickupPersonPhoneNumber()}
                placeholder={lang.labels.AuthorizedPickupPersonPhoneNumber()}
                name={fnames.authorizedPickupPersonPhoneNumber}
              />
            </ContentRevealer>
          </FormSectionCard>
        </SectionWrapper>

        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.Expense()}
            titleIcon={icons.money}
          >
            <div className="row max-input-width">
              <div className="column">
                <FoxInputNumberDecimalField
                  label={`${lang.labels.Distance()} (km)`}
                  placeholder={lang.labels.Distance()}
                  name={fnames.distance}
                  min={0}
                  required
                  onChange={onDistanceChange}
                />

                <FoxInputNumberDecimalField
                  label={lang.labels.PricePerKilometer()}
                  placeholder={lang.labels.PricePerKilometer()}
                  name={fnames.pricePerKilometer}
                  required
                  onChange={onPricePerKilometerChange}
                />

                <CurrencyPriceInputWrapper>
                  <FoxInputNumberDecimalField
                    label={lang.labels.StartPrice()}
                    placeholder={lang.labels.StartPrice()}
                    name={fnames.startPrice}
                    step={10}
                    required
                    onChange={onStartPriceChange}
                  />

                  <FoxComboBoxField
                    label={lang.labels.Currency()}
                    placeholder={lang.labels.Currency()}
                    name={fnames.currency}
                    items={currencies}
                    required
                  />
                </CurrencyPriceInputWrapper>

                <FoxButton
                  primary
                  type="button"
                  onClick={onResetPrices}
                  disabled={submitting}
                >
                  {lang.buttons.Reset()}
                </FoxButton>
              </div>
            </div>
          </FormSectionCard>
        </SectionWrapper>

        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.Transport()}
            titleIcon={icons.truck}
          >
            <FoxInputNumberField
              label={`${lang.labels.Capacity()} (kg)`}
              placeholder={lang.labels.Capacity()}
              name={fnames.capacity}
              required
            />

            <FoxRadioButtonsField
              label={lang.labels.LoadingMethod()}
              name={fnames.loadingMethod}
              items={loadingMethods}
              required
            />

            <FoxRadioButtonsField
              label={lang.labels.UnloadingMethod()}
              name={fnames.unloadingMethod}
              items={unloadingMethods}
              required
            />

            <FoxInputTextareaField
              label={lang.labels.AdditionalRequirements()}
              placeholder={lang.labels.AdditionalRequirements()}
              name={fnames.additionalRequirements}
            />
          </FormSectionCard>
        </SectionWrapper>
      </fieldset>
      <FoxButton primary disabled={submitting}>
        {lang.buttons.Update()}
      </FoxButton>
      {isDraft && (
        <FoxButton
          primary
          type="button"
          disabled={submitting}
          onClick={handleSubmit(onPublish)}
        >
          {lang.buttons.UpdateAndPublish()}
        </FoxButton>
      )}
      <FoxButton primary type="button" onClick={onCancelClick}>
        {lang.buttons.Cancel()}
      </FoxButton>

      {isDraft && (
        <FoxConfirmationButton
          buttonText={lang.labels.DeleteAuction()}
          onYesClick={() => onRemove()}
          className="button button_error"
        />
      )}
    </Form>
  );
}

AuctionInformationEditForm.propTypes = propTypes;

const onChangeZipCode = onChangeZipCodeFactory(
  formNames.auctionInformationForm,
);

function onChange(values, dispatch, props, previousValues) {
  onChangeAddresses(values, previousValues, props, dispatch);
  onChangeZipCode(values, previousValues, props, dispatch);
  if (props.dirty && fieldChanged(values, previousValues, 'distance')) {
    props.onDistanceChange(values.distance);
  }

  if (!props.dirty) {
    return;
  }

  if (fieldChanged(values, previousValues, 'loadingTimeFrom')) {
    dispatch(
      props.change(
        fnames.loadingTimeTo,
        moment(values.loadingTimeFrom).add(2, 'days'),
      ),
    );
  }

  if (fieldChanged(values, previousValues, 'unloadingTimeFrom')) {
    dispatch(
      props.change(
        fnames.unloadingTimeTo,
        moment(values.unloadingTimeFrom).add(2, 'days'),
      ),
    );
  }
}

const onSubmitSuccess = (result, dispatch) =>
  displayCompletionToast(
    dispatch,
    lang.labels.SuccessfullyModificatedAuction(),
  );

export default reduxForm({
  form: formNames.auctionInformationForm,
  validate,
  enableReinitialize: true,
  onChange,
  onSubmitFail: scrollToTheFirstError,
  onSubmitSuccess,
})(AuctionInformationEditForm);
