"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _actionTypes = _interopRequireDefault(require("./actionTypes"));

var _appLanguages = require("./appLanguages");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function getCookie(name) {
  return document.cookie.split('; ').reduce(function (r, v) {
    var parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');
}

var initialState = getCookie('lang') || _appLanguages.appDefaultLanguage;

var _default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actionTypes.default.LANGUAGE_CHANGE:
      return action.language;

    default:
      return state;
  }
};

exports.default = _default;