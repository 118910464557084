import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, getFormValues } from 'redux-form';
import { useSelector } from 'react-redux';
import { isRequired } from 'fox-react/dist/utils/formValidators';
import Form from 'fox-react/dist/components/Form';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import goBackAfterSuccess from 'fox-react/dist/utils/goBackAfterSuccess';
import FoxInputNumberField from 'fox-react/dist/reduxFormFields/FoxInputNumberField';
import FoxSwitchField from 'fox-react/dist/reduxFormFields/FoxSwitchField';
import lang from 'hh-shared/dist/language/services/languageService';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import icons from 'hh-shared/dist/consts/icons';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import { displayCompletionToast } from 'hh-shared/dist/consts/reduxFormMethods';
import ZipCodeAutocompleteField from 'commons/ZipCodeAutocompleteField';
import {
  isRequiredFieldValidator,
  isNipFieldValidator,
} from 'hh-shared/dist/utils/formValidators';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const defaultProps = {};

function EditDepartmentInformationForm({ handleSubmit, submitting }) {
  const {
    editAuctionEnumerator,
    editAuctionDraftEnumerator,
    editTransportOrderEnumerator,
  } = useSelector(state =>
    getFormValues(formNames.departmentInformationForm)(state),
  );

  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.DepartmentInformations()}
            titleIcon={icons.department}
          >
            <FoxInputTextField
              label={lang.labels.Name()}
              name={fieldNames.departmentInformationForm.name}
              placeholder={lang.labels.Name()}
              required
              validate={isRequiredFieldValidator}
            />

            <FoxSwitchField
              label={lang.labels.EditAuctionEnumerator()}
              name={fieldNames.departmentInformationForm.editAuctionEnumerator}
            />

            <FoxInputNumberField
              label={lang.labels.AuctionEnumerator()}
              name={fieldNames.departmentInformationForm.auctionEnumerator}
              placeholder={lang.labels.AuctionEnumerator()}
              required
              disabled={!editAuctionEnumerator}
            />

            <FoxSwitchField
              label={lang.labels.EditAuctionDraftEnumerator()}
              name={
                fieldNames.departmentInformationForm.editAuctionDraftEnumerator
              }
            />

            <FoxInputNumberField
              label={lang.labels.AuctionDraftEnumerator()}
              name={fieldNames.departmentInformationForm.auctionDraftEnumerator}
              placeholder={lang.labels.AuctionDraftEnumerator()}
              required
              disabled={!editAuctionDraftEnumerator}
            />

            <FoxSwitchField
              label={lang.labels.EditTransportOrderEnumerator()}
              name={
                fieldNames.departmentInformationForm
                  .editTransportOrderEnumerator
              }
            />

            <FoxInputNumberField
              label={lang.labels.TransportOrderEnumerator()}
              name={
                fieldNames.departmentInformationForm.transportOrderEnumerator
              }
              placeholder={lang.labels.TransportOrderEnumerator()}
              required
              disabled={!editTransportOrderEnumerator}
            />
          </FormSectionCard>
        </SectionWrapper>

        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.DataForTransportOrder()}
            titleIcon={icons.editedDocument}
          >
            <FoxInputTextField
              label={lang.labels.FullName()}
              name={fieldNames.departmentInformationForm.fullName}
              placeholder={lang.labels.FullName()}
              required
              validate={isRequiredFieldValidator}
            />

            <FoxInputTextField
              label={lang.labels.Nip()}
              placeholder={lang.labels.Nip()}
              name={fieldNames.departmentInformationForm.nip}
              required
              validate={[isRequiredFieldValidator, isNipFieldValidator]}
            />

            <ZipCodeAutocompleteField
              required
              validate={isRequiredFieldValidator}
            />

            <FoxInputTextField
              label={lang.labels.City()}
              placeholder={lang.labels.City()}
              name={fieldNames.departmentInformationForm.city}
              required
              validate={isRequiredFieldValidator}
            />

            <FoxInputTextField
              label={lang.labels.Street()}
              placeholder={lang.labels.Street()}
              name={fieldNames.departmentInformationForm.street}
              required
              validate={isRequiredFieldValidator}
            />
          </FormSectionCard>
        </SectionWrapper>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.DataForInvoiceTransportOrder()}
            titleIcon={icons.documentWithData}
          >
            <FoxInputTextField
              label={lang.labels.FullName()}
              name={fieldNames.departmentInformationForm.invoiceDestinationName}
              placeholder={lang.labels.FullName()}
              required
              validate={isRequiredFieldValidator}
            />

            <ZipCodeAutocompleteField
              required
              name={
                fieldNames.departmentInformationForm.invoiceDestinationZipCode
              }
              validate={isRequiredFieldValidator}
            />

            <FoxInputTextField
              label={lang.labels.City()}
              placeholder={lang.labels.City()}
              name={fieldNames.departmentInformationForm.invoiceDestinationCity}
              required
              validate={isRequiredFieldValidator}
            />

            <FoxInputTextField
              label={lang.labels.Street()}
              placeholder={lang.labels.Street()}
              name={
                fieldNames.departmentInformationForm.invoiceDestinationStreet
              }
              required
              validate={isRequiredFieldValidator}
            />

            <FoxInputTextField
              label={lang.labels.InvoiceEmailAddress()}
              placeholder={lang.labels.InvoiceEmailAddress()}
              name={fieldNames.departmentInformationForm.emailInvoiceAddress}
              required
              validate={isRequiredFieldValidator}
            />
          </FormSectionCard>
        </SectionWrapper>
      </fieldset>
      <FoxButton primary disabled={submitting}>
        {lang.buttons.Save()}
      </FoxButton>
    </Form>
  );
}

EditDepartmentInformationForm.propTypes = propTypes;
EditDepartmentInformationForm.defaultProps = defaultProps;

const validate = values => {
  const errors = {};

  values.editAuctionEnumerator &&
    isRequired(
      errors,
      values,
      fieldNames.departmentInformationForm.auctionEnumerator,
      lang.validationMessages.FieldRequired(),
    );

  values.editAuctionDraftEnumerator &&
    isRequired(
      errors,
      values,
      fieldNames.departmentInformationForm.auctionDraftEnumerator,
      lang.validationMessages.FieldRequired(),
    );

  values.editTransportOrderEnumerator &&
    isRequired(
      errors,
      values,
      fieldNames.departmentInformationForm.transportOrderEnumerator,
      lang.validationMessages.FieldRequired(),
    );

  return errors;
};

const onSubmitSuccess = (result, dispatch) =>
  displayCompletionToast(
    dispatch,
    lang.labels.SuccessfullyModificatedDeparment(),
  );

export default reduxForm({
  form: formNames.departmentInformationForm,
  validate,
  enableReinitialize: true,
  onSubmitSuccess,
})(goBackAfterSuccess(EditDepartmentInformationForm));
