import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Toastr from 'hh-shared/dist/toastr/Toastr';

import permissions from 'common/permissions';
import OnlyAnonymousRoute from 'commons/OnlyAnonymousRoute';
import ProtectedRoute from 'commons/ProtectedRoute';
import RouteWithLayout from 'hh-shared/dist/components/commons/RouteWithLayout';

import DefaultAnonymousLayout from 'layout/DefaultAnonymousLayout';

import DashboardPage from 'dashboard/DashboardPage';

import LoginPageContainer from 'auth/LoginPageContainer';
import ResetPasswordPageContainer from 'auth/ResetPassword/ResetPasswordPageContainer';
import SetNewPasswordPageContainer from 'auth/SetNewPassword/SetNewPasswordPageContainer';
import MyProfilePage from 'auth/MyProfile/MyProfilePage';

import UsersManagementPageContainer from 'users-management/UsersManagementPageContainer';
import UsersManagementAddPageContainer from 'users-management/UsersManagementAddPageContainer';
import UsersManagementEditPageContainer from 'users-management/UsersManagementEditPageContainer';

import NotificationTemplatesManagementPage from 'html-templates-management/HtmlTemplatesManagementPage';
import EditNotificationTemplatePage from 'html-templates-management/EditPage/EditPage';

import CompanyManagementPageContainer from 'company-management/CompanyManagementPageContainer';
import CompanyManagementAddPage from 'company-management/CompanyManagementAddPage';
import CompanyManagementEditPage from 'company-management/CompanyManagementEditPage';

import CarTypesManagementPageContainer from 'car-types-management/CarTypesManagementPageContainer';
import CarTypesManagementAddPageContainer from 'car-types-management/CarTypesManagementAddPageContainer';
import CarTypesManagementEditPageContainer from 'car-types-management/CarTypesManagementEditPageContainer';

import CarsManagementPageContainer from 'cars-management/CarsManagementPageContainer';
import CarsManagementAddPageContainer from 'cars-management/CarsManagementAddPageContainer';
import CarsManagementEditPageContainer from 'cars-management/CarsManagementEditPageContainer';

import FacilitiesManagementPageContainer from 'facilities-management/FacilitiesManagementPageContainer';
import FacilitiesManagementAddPageContainer from 'facilities-management/FacilitiesManagementAddPageContainer';
import FacilitiesManagementEditPageContainer from 'facilities-management/FacilitiesManagementEditPageContainer';

import RoutesManagementEditPage from 'routes-management/RoutesManagementEditPage';

import CurrenciesManagementPageContainer from 'currencies-management/CurrenciesManagementPageContainer';
import CurrenciesManagementAddPageContainer from 'currencies-management/CurrenciesManagementAddPageContainer';
import CurrenciesManagementEditPageContainer from 'currencies-management/CurrenciesManagementEditPageContainer';

import AuctionsManagementAddPageContainer from 'auctions-management/AuctionsManagementAddPageContainer';
import AuctionsManagementAddBasedOnAuctionPageContainer from 'auctions-management/AuctionsManagementAddBasedOnAuctionPageContainer';
import AuctionsManagementEditPageContainer from 'auctions-management/AuctionsManagementEditPageContainer';
import AuctionPage from 'auction/AuctionPage';

import TransportOrdersManagementPageContainer from 'transport-orders-management/TransportOrdersManagementPageContainer';
import TransportOrdersManagementAddPageContainer from 'transport-orders-management/TransportOrdersManagementAddPageContainer';
import TransportOrdersManagementAddBasedOnAuctionPageContainer from 'transport-orders-management/TransportOrdersManagementAddBasedOnAuctionPageContainer';
import TransportOrdersManagementEditPageContainer from 'transport-orders-management/TransportOrdersManagementEditPageContainer';

import AddressesManagementPageContainer from 'addresses-management/AddressesManagementPageContainer';
import AddressesManagementAddPage from 'addresses-management/AddressesManagementAddPage';
import AddressesManagementEditPageContainer from 'addresses-management/AddressesManagementEditPageContainer';

import DepartmentsManagementPage from 'departments-management/DepartmentsManagementPage';
import DepartmentsManagementAddPage from 'departments-management/DepartmentsManagementAddPage';
import DepartmentsManagementEditPage from 'departments-management/DepartmentsManagementEditPage';

import PricesManagementPageContainer from 'prices-management/PricesManagementPageContainer';

import DefaultPricesManagementPageContainer from 'default-prices-management/DefaultPricesManagementPageContainer';
import DefaultPricesManagementAddPageContainer from 'default-prices-management/DefaultPricesManagementAddPageContainer';
import DefaultPricesManagementEditPageContainer from 'default-prices-management/DefaultPricesManagementEditPageContainer';

import TransportOrderPreviewPage from 'transport-orders-management/TransportOrderPreviewPage/TransportOrderPreviewPage';

import Page404 from 'hh-shared/dist/components/commons/Page404';
import AuctionsManagementPage from 'auctions-management/AuctionsManagementPage';

import ZipCodesManagementPage from './zip-codes-management/ZipCodesManagementPage';
import ZipCodesManagementAddPage from './zip-codes-management/ZipCodesManagementAddPage';
import ZipCodesManagementEditPage from './zip-codes-management/ZipCodesManagementEditPage';
import RoutesManagementPage from './routes-management/RoutesManagementPage';
import RoutesManagementAddPage from './routes-management/RoutesManagementAddPage';

function App() {
  return (
    <div className="App">
      <Switch>
        <ProtectedRoute exact path="/" component={DashboardPage} />
        <OnlyAnonymousRoute path="/login" component={LoginPageContainer} />
        <OnlyAnonymousRoute
          path="/reset-password"
          component={ResetPasswordPageContainer}
        />
        <RouteWithLayout
          layout={DefaultAnonymousLayout}
          path="/set-new-password/:token"
          component={SetNewPasswordPageContainer}
        />
        <ProtectedRoute exact path="/my-profile" component={MyProfilePage} />
        <ProtectedRoute exact path="/my-settings" component={MyProfilePage} />

        <ProtectedRoute
          exact
          path="/auctions-management"
          component={AuctionsManagementPage}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.EMPLOYEE]}
        />
        <ProtectedRoute
          exact
          path="/auctions-management/add"
          component={AuctionsManagementAddPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.EMPLOYEE]}
        />
        <ProtectedRoute
          exact
          path="/auctions-management/add-based-on-auction/:auctionId"
          component={AuctionsManagementAddBasedOnAuctionPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.EMPLOYEE]}
        />
        <ProtectedRoute
          exact
          path="/auctions-management/edit/:id"
          component={AuctionsManagementEditPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.EMPLOYEE]}
        />
        <ProtectedRoute
          exact
          path="/auctions-management/details/:id"
          component={AuctionPage}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.EMPLOYEE]}
        />
        <ProtectedRoute
          exact
          path="/transport-orders-management"
          component={TransportOrdersManagementPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.EMPLOYEE]}
        />
        <ProtectedRoute
          exact
          path="/transport-orders-management/add"
          component={TransportOrdersManagementAddPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.EMPLOYEE]}
        />
        <ProtectedRoute
          exact
          path="/transport-orders-management/add-based-on-auction/:auctionId"
          component={TransportOrdersManagementAddBasedOnAuctionPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.EMPLOYEE]}
        />
        <ProtectedRoute
          exact
          path="/transport-orders-management/edit/:id"
          component={TransportOrdersManagementEditPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.EMPLOYEE]}
        />

        <ProtectedRoute
          exact
          path="/transport-orders-management/preview/:id"
          component={TransportOrderPreviewPage}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.EMPLOYEE]}
        />

        <ProtectedRoute
          exact
          path="/html-templates-management"
          component={NotificationTemplatesManagementPage}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />

        <ProtectedRoute
          path="/html-templates-management/edit-content/:id"
          component={EditNotificationTemplatePage}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />

        <ProtectedRoute
          path="/html-templates-management/edit-emailContent/:id"
          component={EditNotificationTemplatePage}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />

        <ProtectedRoute
          path="/html-templates-management/edit-smsContent/:id"
          component={EditNotificationTemplatePage}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />

        <ProtectedRoute
          exact
          path="/users-management"
          component={UsersManagementPageContainer}
        />
        <ProtectedRoute
          path="/users-management/add"
          component={UsersManagementAddPageContainer}
        />
        <ProtectedRoute
          path="/users-management/edit/:instanceId"
          component={UsersManagementEditPageContainer}
        />

        <ProtectedRoute
          exact
          path="/departments"
          component={DepartmentsManagementPage}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />
        <ProtectedRoute
          path="/departments/add"
          component={DepartmentsManagementAddPage}
          oneOfPermissions={[permissions.ADMIN]}
        />
        <ProtectedRoute
          path="/departments/edit/:id"
          component={DepartmentsManagementEditPage}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />
        <ProtectedRoute
          exact
          path="/car-types-management"
          component={CarTypesManagementPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />
        <ProtectedRoute
          exact
          path="/car-types-management/add"
          component={CarTypesManagementAddPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />
        <ProtectedRoute
          exact
          path="/car-types-management/edit/:id"
          component={CarTypesManagementEditPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />
        <ProtectedRoute
          exact
          path="/cars-management"
          component={CarsManagementPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />
        <ProtectedRoute
          exact
          path="/cars-management/add"
          component={CarsManagementAddPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />
        <ProtectedRoute
          exact
          path="/cars-management/edit/:instanceId"
          component={CarsManagementEditPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />
        <ProtectedRoute
          exact
          path="/facilities-management"
          component={FacilitiesManagementPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />
        <ProtectedRoute
          exact
          path="/facilities-management/add"
          component={FacilitiesManagementAddPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />
        <ProtectedRoute
          exact
          path="/facilities-management/edit/:instanceId"
          component={FacilitiesManagementEditPageContainer}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />
        <ProtectedRoute
          exact
          path="/routes-management"
          component={RoutesManagementPage}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />
        <ProtectedRoute
          exact
          path="/routes-management/add"
          component={RoutesManagementAddPage}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />
        <ProtectedRoute
          exact
          path="/routes-management/edit/:id"
          component={RoutesManagementEditPage}
          oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
        />

        <ProtectedRoute
          exact
          path="/companies-management"
          component={CompanyManagementPageContainer}
        />
        <ProtectedRoute
          exact
          path="/companies-management/add"
          component={CompanyManagementAddPage}
        />
        <ProtectedRoute
          exact
          path="/companies-management/edit/:instanceId"
          component={CompanyManagementEditPage}
        />

        <ProtectedRoute
          exact
          path="/currencies-management"
          component={CurrenciesManagementPageContainer}
          oneOfPermissions={[permissions.ADMIN]}
        />
        <ProtectedRoute
          exact
          path="/currencies-management/add"
          component={CurrenciesManagementAddPageContainer}
          oneOfPermissions={[permissions.ADMIN]}
        />
        <ProtectedRoute
          exact
          path="/currencies-management/edit/:id"
          component={CurrenciesManagementEditPageContainer}
          oneOfPermissions={[permissions.ADMIN]}
        />

        <ProtectedRoute
          exact
          path="/addresses-management"
          component={AddressesManagementPageContainer}
        />
        <ProtectedRoute
          exact
          path="/addresses-management/add"
          component={AddressesManagementAddPage}
        />
        <ProtectedRoute
          exact
          path="/addresses-management/edit/:instanceId"
          component={AddressesManagementEditPageContainer}
        />

        <ProtectedRoute
          exact
          path="/prices-management/"
          component={PricesManagementPageContainer}
          oneOfPermissions={[permissions.PRICES_MANAGEMENT]}
        />

        <ProtectedRoute
          exact
          path="/default-prices-management"
          component={DefaultPricesManagementPageContainer}
          oneOfPermissions={[permissions.PRICES_MANAGEMENT]}
        />
        <ProtectedRoute
          exact
          path="/default-prices-management/add"
          component={DefaultPricesManagementAddPageContainer}
          oneOfPermissions={[permissions.PRICES_MANAGEMENT]}
        />
        <ProtectedRoute
          exact
          path="/default-prices-management/edit/:id"
          component={DefaultPricesManagementEditPageContainer}
          oneOfPermissions={[permissions.PRICES_MANAGEMENT]}
        />

        <ProtectedRoute
          exact
          path="/zip-codes-management"
          component={ZipCodesManagementPage}
        />
        <ProtectedRoute
          exact
          path="/zip-codes-management/add"
          component={ZipCodesManagementAddPage}
        />
        <ProtectedRoute
          exact
          path="/zip-codes-management/edit/:id"
          component={ZipCodesManagementEditPage}
        />

        <Route component={Page404} />
      </Switch>

      <Toastr />
    </div>
  );
}

export default App;
