import React, {memo, useCallback} from 'react';
import styles from './LanguageBar.module.scss';
import {appDefaultLanguage, supportedLanguages} from 'hh-shared/dist/language/appLanguages';
import cx from 'classnames';
import {useSelector} from 'react-redux';
import {setCookie} from '../utils/cookies';

function LanguageBar() {
  const currentLanguage = useSelector((state) => state.language) || appDefaultLanguage;

  const change = useCallback(language => {
    setCookie('lang', language);
    window.location.reload();
  }, []);

  return (
    <div className={styles.container}>
      {supportedLanguages.map(lang => {
        const className = cx(styles.language, {
          [styles.active]: lang === currentLanguage,
        });
        return (
          <span key={lang} className={className} onClick={() => change(lang)}>
            {lang}
          </span>
        );
      })}
    </div>
  );
}

export default memo(LanguageBar);
