"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _labels = _interopRequireDefault(require("./translations/labels.pl"));

var _labels2 = _interopRequireDefault(require("./translations/labels.en"));

var _labels3 = _interopRequireDefault(require("./translations/labels.de"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // File was autogenerated by tools/generateLabels.js script


var _default = {
  pl: _labels.default,
  en: _labels2.default,
  de: _labels3.default
};
exports.default = _default;