"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.prepareFormData = prepareFormData;
exports.downloadFile = exports.base64toBlob = void 0;

function prepareFormData(files) {
  var formData = new FormData();

  for (var i = 0, length = files.affectedFiles.length; i < length; i++) {
    formData.append('files', files.affectedFiles[i].getRawFile(), files.affectedFiles[i].name);
    formData.append("file".concat(i), files.affectedFiles[i].uid);
    formData.append("file".concat(i), files.affectedFiles[i].name);
  }

  return formData;
}

var base64toBlob = function base64toBlob(base64Data) {
  var sliceSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 512;
  var byteCharacters = atob(base64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);

    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays);
};

exports.base64toBlob = base64toBlob;

var downloadFile = function downloadFile(file) {
  var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  var fileURL = URL.createObjectURL(file);
  a.href = fileURL;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(fileURL);
};

exports.downloadFile = downloadFile;