"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // File was autogenerated by tools/generateLabels.js script

var _default = {
  AcceptForImplementation: "Notify",
  Add: "Add",
  AddDriver: "Add the driver",
  AddNewDriver: "Add a new driver",
  Back: "Return",
  Cancel: "Cancel",
  CloneAuction: "Duplicate auctions",
  CreateTransportOrder: "Create an order",
  Delete: "Remove",
  Edit: "Edit",
  EditContent: "Edit content",
  EditEmailContent: "Edit e-mail content",
  EditSmsContent: "Edit SMS content",
  GetFromGus: "Download from the CSO",
  GetPrice: "Download the price",
  Loaded: "Loaded",
  LogIn: "Log in",
  LogOut: "Log out",
  Realized: "Realized",
  RemoveDriver: "Remove the driver",
  Reset: "Reset",
  ResetPassword: "Reset password",
  Save: "Save",
  SaveAndPublish: "Save and publish",
  SaveAsDraft: "Save as a draft",
  SaveAsNew: "Save as a new version",
  Search: "Search",
  SetNewPassword: "Set a new password",
  Show: "Show",
  Update: "Update",
  UpdateAndPublish: "Update and publish",
  CancelOrder: "Cancel the order",
  AddNewCar: "Add a new vehicle",
  RemoveCar: "Remove the vehicle",
  SendInvitationToPlatform: "Send an invitation to the platform",
  SendApplicationForApproval: "Send a notification for approval",
  Send: "Send",
  Download: "Download",
  RemoveCompanyAttachment: "Remove the attachment",
  UpdateImplementation: "Update notification",
  Generate: "Generate"
};
exports.default = _default;