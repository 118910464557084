import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import cx from 'classnames';

import Row from 'layout/Row';
import Column from 'layout/Column';
import formNames from 'common/formNames';
import fieldNames from 'common/fieldNames';
import permissions from 'common/permissions';
import PermissionWrapper from 'commons/PermissionWrapper';
import icons from 'hh-shared/dist/consts/icons';
import FontAwesomeIcon from 'hh-shared/dist/components/commons/FontAwesomeIcon/FontAwesomeIcon';
import lang from 'hh-shared/dist/language/services/languageService';
import FoxSwitchField from 'fox-react/dist/reduxFormFields/FoxSwitchField';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';

import './UserPermissionsTheming.scss';
import styles from './UserPermissions.module.scss';

const propTypes = {};

const defaultProps = {};

function UserPermissions() {
  const formValues = useSelector(
    state => getFormValues(formNames.userInformationForm)(state) || {},
  );

  const [currentPermissions, setCurrentPermissions] = useState({});

  useEffect(() => {
    if (formValues.permissions) {
      const currentFormPermissions = formValues.permissions;
      currentFormPermissions && setCurrentPermissions(currentFormPermissions);
    }
  }, [formValues]);

  const iconClassName = isActive =>
    cx('text', `text_${isActive ? 'accent' : 'grey'}`);

  return (
    <SectionWrapper>
      <FormSectionCard
        title={lang.labels.UserPermissions()}
        titleIcon={icons.permissions}
        fullContentWidth
      >
        {/* Kontener tylko na potrzebe stylowania ikonek uprawnień, w razie zmiany konceptu, nie bać się i wywalić xD */}
        <div className="permissions_container">
          <Column className={styles.main_column}>
            <Row className="justify-content-space-between">
              <Column>
                <PermissionWrapper oneOfPermissions={[permissions.ADMIN]}>
                  <Row>
                    <FontAwesomeIcon
                      icon={icons.mainAdmin}
                      title={lang.labels.MainAdmin()}
                      className={iconClassName(currentPermissions.ADMIN)}
                    />
                    <FoxSwitchField
                      label={lang.labels.MainAdmin()}
                      name={fieldNames.userPermissions.ADMIN}
                    />
                  </Row>
                </PermissionWrapper>

                <PermissionWrapper
                  oneOfPermissions={[permissions.DEP_ADMIN, permissions.ADMIN]}
                >
                  <Row>
                    <FontAwesomeIcon
                      icon={icons.departmentAdmin}
                      title={lang.labels.DepAdmin()}
                      className={iconClassName(currentPermissions.DEP_ADMIN)}
                    />
                    <FoxSwitchField
                      label={lang.labels.DepAdmin()}
                      name={fieldNames.userPermissions.DEP_ADMIN}
                    />
                  </Row>
                  <Row>
                    <FontAwesomeIcon
                      icon={icons.employee}
                      title={lang.labels.Employee()}
                      className={iconClassName(currentPermissions.EMPLOYEE)}
                    />
                    <FoxSwitchField
                      label={lang.labels.Employee()}
                      name={fieldNames.userPermissions.EMPLOYEE}
                    />
                  </Row>
                </PermissionWrapper>
              </Column>
              <Column>
                <Row>
                  <FontAwesomeIcon
                    icon={icons.pricesManagement}
                    title={lang.labels.PricesManagement()}
                    className={iconClassName(
                      currentPermissions.PRICES_MANAGEMENT,
                    )}
                  />
                  <FoxSwitchField
                    label={lang.labels.PricesManagement()}
                    name={fieldNames.userPermissions.PRICES_MANAGEMENT}
                  />
                </Row>
                <PermissionWrapper oneOfPermissions={[permissions.ADMIN]}>
                  <Row>
                    <FontAwesomeIcon
                      icon={icons.company}
                      title={lang.labels.ActivateCompany()}
                      className={iconClassName(
                        currentPermissions.ACTIVATE_COMPANY,
                      )}
                    />
                    <FoxSwitchField
                      label={lang.labels.ActivateCompany()}
                      name={fieldNames.userPermissions.ACTIVATE_COMPANY}
                    />
                  </Row>
                </PermissionWrapper>
              </Column>
            </Row>
            <Row>
              <FontAwesomeIcon
                icon={icons.logistic}
                title={lang.labels.Logistician()}
                className={iconClassName(currentPermissions.LOGISTICIAN)}
              />
              <FoxSwitchField
                label={lang.labels.Logistician()}
                name={fieldNames.userPermissions.LOGISTICIAN}
              />
            </Row>
          </Column>
        </div>
      </FormSectionCard>
    </SectionWrapper>
  );
}

UserPermissions.propTypes = propTypes;
UserPermissions.defaultProps = defaultProps;

export default UserPermissions;
