"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // File was autogenerated by tools/generateLabels.js script

var _default = {
  Edit: "Auflage",
  EditProfile: "Profilbearbeitung",
  EmailOrLogin: "E-Mail oder Anmeldung",
  LoginPage_ForgotPassword: "Ich habe mein Passwort vergessen",
  LoginPage_Login: "Anmeldung",
  LoginPage_LoginDescription: "Nach der Autorisierung erhalten Sie Zugriff auf das Verwaltungsgremium",
  LogOut: "Ausloggen",
  Password: "Passwort",
  Welcome: "Hallo",
  Email: "Email",
  ResetPasswordInformation: "Ein Passwortlink wird an die angegebene E-Mail-Adresse gesendet",
  NewPassword: "Ein neues Passwort",
  RepeatPassword: "Passwort wiederholen",
  BackToMainPage: "Zurück zur Hauptseite",
  LoginPage: "Loginseite",
  UsersManagement_Columns_Email: "Email",
  UsersManagement_Columns_Login: "Anmeldung",
  YourPasswordWasChanged: "Ihr Passwort wurde geändert",
  HtmlTemplatesManagement_Columns_Description: "Beschreibung",
  HtmlTemplatesManagement_Columns_Name: "Name",
  AddingNewUser: "Hinzufügen eines neuen Benutzer",
  UserInformations: "Benutzerdaten",
  Login: "Anmeldung",
  Roles: "Rolle",
  EditingUser: "Benutzerausgabe",
  UserManagement_Columns_Roles: "Rollen",
  AddingNewDepartment: "Hinzufügen eines neuen Zweigs",
  DepartmentInformations: "Abteilung der Zweig",
  Name: "Name",
  UserManagement_Columns_Name: "Name",
  EditingDepartment: "Ausgabe des Zweigs",
  Content: "Inhalt",
  Description: "Beschreibung",
  EditingHtmlTemplates: "HTML Edition",
  HtmlTemplateInformations: "HTML -Vorlagendaten",
  FirstName: "Name",
  LastName: "Familienname, Nachname",
  UsersManagement_Columns_FirstName: "Name",
  UsersManagement_Columns_LastName: "Familienname, Nachname",
  CurrentPassword: "Aktuelles Passwort",
  UserChangePassword: "Passwortänderung",
  Companies: "Firmen",
  AddingNewCompany: "Hinzufügen eines neuen Unternehmens",
  Address: "Adresse",
  City: "Stadt",
  CompanyInformations: "Daten des Unternehmens",
  EditingCompany: "Firmenausgabe",
  Nip: "Nip",
  PhoneNumber: "Telefonnummer",
  ZipCode: "PLZ",
  Version: "Ausführung",
  AvailablePlaceholders: "Platzhalter verfügbar",
  AddingNewCar: "Hinzufügen eines neuen Fahrzeugs",
  AddingNewCarType: "Hinzufügen eines neuen Fahrzeugtyps",
  CarInformations: "Fahrzeugdaten",
  CarTypeInformations: "Fahrzeugtypdaten",
  EditingCar: "Fahrzeugbearbeitung",
  EditingCarType: "Fahrzeugtyp -Bearbeitung",
  Cars: "Fahrzeuge",
  CarType: "Fahrzeugtyp",
  CarTypes: "Fahrzeugtypen",
  CarTypesManagement_Columns_Name: "Name",
  Comment: "Kommentar",
  Company: "Geschäft",
  RegisterNumber: "Registrationsnummer",
  AddingNewFacility: "Hinzufügen einer neuen Anlage",
  Department: "Zweig",
  EditingFacilities: "Bearbeitung der Pflanze",
  Facilities: "Wetten",
  FacilityInformations: "Abteilung der Anlage",
  AdditionalUserInformations: "Zusätzliche Benutzerdaten",
  AddingNewRoute: "Hinzufügen einer neuen Route",
  CityFrom: "Anfangsstadt",
  CityTo: "Target City",
  Distance: "Distanz",
  EditingRoute: "Ausgabe der Route",
  RouteInformations: "Routendaten",
  Routes: "Routen",
  ZipCodeFrom: "Ausgangs-Postleitzahl",
  ZipCodeTo: "Ziel-Postleitzahl",
  Departments: "Abteilungen",
  HtmlTemplates: "HTML -Vorlagen",
  Users: "Benutzer",
  Avatar: "Avatar",
  AddingNewAuction: "Hinzufügen einer neuen Auktion",
  AuctionInformation: "Auktionsdaten",
  AuctionNumber: "Auktionsnummer",
  Auctions: "Auktionen",
  AdditionalRequirements: "Zusätzliche Anforderungen",
  AdditionalInformations: "Weitere Informationen",
  NoAdditionalInformations: "Keine zusätzlichen Informationen",
  AuctionType: "Auktionstyp",
  AuthorizedPickupPerson: "Firma/Person zur Abholung befugt",
  AuthorizedPickupPersonInformations: "Daten der Person, die zur Abholung befugt ist",
  Capacity: "Kapazität",
  EndDateTime: "Datum und Uhrzeit",
  Facility: "Wette",
  LoadingDateTime: "Datum und Uhrzeit des Ladens",
  LoadingMethod: "Lademethode",
  LoadingData: "Daten werden geladen",
  PricePerKilometer: "Preis pro Kilometer",
  StartDateTime: "Datums- und Startzeit",
  StartPrice: "Startpreis",
  StartingPrice: "Startpreis",
  UnloadingData: "Daten entladen",
  UnloadingDateTime: "Datum und Uhrzeit des Entladens",
  UnloadingMethod: "Entladung",
  AddingNewCurrency: "Hinzufügen einer neuen Währung",
  Currencies: "Währung",
  CurrenciesManagement_Columns_IsDefault: "Oder Standard",
  CurrenciesManagement_Columns_Name: "Name",
  CurrencyInformations: "Währungsdaten",
  EditingCurrency: "Währungsausgabe",
  IsDefault: "Oder Standard",
  PrincipalPerson: "Rektor",
  Route: "Route",
  Subjects: "Entitäten",
  AddingNewTransportOrder: "Hinzufügen einer neuen Bestellung",
  Car: "Fahrzeug",
  Driver: "Fahrer",
  EditingTransportOrder: "Bestellbearbeitung",
  RealizationDate: "Datum der Implementierung",
  TransportOrders: "Aufträge",
  TransportOrdersManagement_Columns_AuctionNumber: "Auktionsnummer",
  TransportOrdersManagement_Columns_Company: "Geschäft",
  TransportOrdersManagement_Columns_Driver: "Fahrer",
  TransportOrdersManagement_Columns_Distance: "Distanz",
  TransportOrdersManagement_Columns_LoadingTime: "Datum und Uhrzeit des Ladens",
  TransportOrdersManagement_Columns_Number: "Nummer",
  TransportOrdersManagement_Columns_Value: "Wert",
  TransportOrdersManagement_Columns_Capacity: "Kapazität",
  TransportOrdersManagement_Columns_UnloadingMethod: "Entladung",
  TransportOrdersManagement_Columns_LoadingMethod: "Lademethode",
  TransportOrdersManagement_Columns_Status: "Status",
  TransportOrdersManagement_Columns_UnloadingAddress: "Ablädungsadresse",
  TransportOrdersManagement_Columns_AuthorizedPickupPerson: "Eine Person, die zur Abholung berechtigt ist",
  TransportOrderInformations: "Bestelldaten",
  IsDriver: "Oder Fahrer",
  UsersManagement_Columns_Company: "Geschäft",
  UsersManagement_Columns_Facility: "Wette",
  UsersManagement_Columns_IsDriver: "Oder Fahrer",
  UsersManagement_Columns_PhoneNumber: "Telefonnummer",
  TransportOrdersManagement_Columns_Car: "Fahrzeug",
  TransportOrdersManagement_Columns_LoadingAddress: "Adresse wird geladen",
  TransportOrdersManagement_Columns_Facility: "Wette",
  TransportOrdersManagement_Columns_UnloadingTime: "Datum und Uhrzeit des Entladens",
  LoadingAddress: "Adresse wird geladen",
  UnloadingAddress: "Ablädungsadresse",
  CurrentPrice: "Derzeitiger Preis",
  Observed: "Beobachtet",
  TimeLeft: "Beenden",
  UnloadingTime: "Datum und Uhrzeit des Entladens",
  AuctionEndTime: "Ende der Auktion",
  ContactPerson: "Gesprächspartner",
  NewAddress: "Neue Adresse",
  Street: "Straße",
  AddressFrom: "Adresse von",
  AddressTo: "Adresse",
  LoadData: "Ladetermin",
  NewCar: "Neues Fahrzeug",
  NewDriver: "Neuer Fahrer",
  Value: "Wert",
  TransportOrdersManagement_Columns_AdditionalRequirements: "Zusätzliche Anforderungen",
  Regon: "Bewertung",
  EditingAuction: "Auktionsausgabe",
  AuctionDraftEnumerator: "Entwurfsauktionsnummer",
  AuctionEnumerator: "Auktionsnummer",
  DepAdmin: "Abteilungsleiter",
  Employee: "Mitarbeiter",
  Logistician: "Logistik",
  MainAdmin: "Hauptadministrator",
  UserPermissions: "Benutzerberechtigungen",
  EditAuctionDraftEnumerator: "Entwurfsauktionserzähler bearbeiten",
  EditAuctionEnumerator: "Auktionsumzähler bearbeiten",
  AddingNewAddress: "Hinzufügen einer neuen Adresse",
  Addresses: "Adressen",
  AddressInformations: "Adressdaten",
  Prices: "Preise",
  PricesManagement_Columns_BuyerRebateGroup: "Gruppe Rabat. Käufer",
  PricesManagement_Columns_Code: "Code",
  PricesManagement_Columns_Company: "Geschäft",
  PricesManagement_Columns_CurrencyCode: "Währungscode",
  PricesManagement_Columns_Description: "Beschreibung",
  PricesManagement_Columns_FlatRate: "Pauschale",
  PricesManagement_Columns_LocalizationCode: "Standortcode",
  PricesManagement_Columns_MaxBasis: "Max. Basis",
  PricesManagement_Columns_MaxDistance: "Max. Distanz",
  PricesManagement_Columns_MinBasis: "Min. Basis",
  PricesManagement_Columns_MinDistance: "Min. Entfernung",
  PricesManagement_Columns_Nip: "Nip",
  PricesManagement_Columns_No: "Ja.",
  PricesManagement_Columns_RatePerBaseUnit: "Rate pro. Grundeinheit",
  PricesManagement_Columns_ShipmentMethod: "Versandart",
  PricesManagement_Columns_ShippingAgentCode: "Schifffahrtscode",
  PricesManagement_Columns_ShippingAgentServiceCode: "Versandvertreter Servicecode",
  PricesManagement_Columns_Type: "Typ",
  PricesManagement_Columns_ZoneNo: "Zone Nr.",
  PricesManagement: "Preismanagement",
  YourOffer: "Ihr Angebot",
  Bid: "Gebot",
  NoOffer: "Kein Angebot",
  You: "Du",
  OrdinalNumber: "LP.",
  HistoricPrice: "Historischer Preis",
  Offerts: "Bietet an",
  Time: "Zeit",
  Suppliers: "Lieferanten",
  InvitedSuppliers: "Eingeladene Lieferanten",
  Loading: "Wird geladen",
  Supply: "Lieferung",
  PriceForKilemeter: "Preis pro km",
  Difference: "Unterschied",
  AuctionHasNotBeenStarted: "Die Auktion wurde nicht begonnen!",
  AuctionHasEnded: "Die Auktion wurde abgeschlossen!",
  Auction: "Versteigerung",
  AuctionTimeDuration: "Dauer der Auktion",
  UserBanned: "Verboten",
  Templates: "Vorlagen",
  Dictionaries: "Wörterbücher",
  AddingNewDefaultPrice: "Hinzufügen eines neuen Standardpreises",
  DefaultPriceInformations: "Daten zum Standardpreis",
  DefaultPrices: "Standardpreise",
  DefaultPricesManagement_Columns_FlatRate: "Rate",
  DefaultPricesManagement_Columns_MaxDistance: "Maximale Entfernung",
  DefaultPricesManagement_Columns_MinDistance: "Mindestabstand",
  EditingDefaultPrice: "Bearbeitung des Standardpreises",
  FlatRate: "Rate",
  MaxDistance: "Maximale Entfernung",
  MinDistance: "Mindestabstand",
  Drivers: "Treiber",
  PriceLists: "Preisliste",
  Blocked: "verstopft",
  BlockingCompany: "Blockieren der Firma",
  Orders: "Aufträge",
  OrdersDashboard: "Aufträge",
  BoxPicture: "Bild der Box",
  Currency: "Währung",
  Expense: "Kosten",
  Transport: "Transport",
  TimeToLoad: "Zeit zum Laden",
  TransportOrder: "Transportauftrag",
  TransportOrderNavNumber: "NAV -Bestellnummer",
  MandatoryCompany: "Obligatorisch",
  ChangeStatus: "Status Änderung",
  Delivery: "Lieferung",
  DeliveryExpense: "Lieferkosten",
  PaymentDeadline: "Zahlungsdatum",
  EditingAddress: "Adresse Bearbeitung",
  Yes: "Ja",
  No: "NEIN",
  DriversQuantity: "Anzahl der Fahrer",
  OrderNavNumber: "NAV -Bestellnummer",
  ZipCodes: "Postleitzahlen",
  MainPage: "Heim",
  SmsNotification: "SMS -Benachrichtigung",
  EmailNotification: "Email Benachrichtigung",
  Day: "Tag",
  Days: "Tage",
  AddingZipCode: "Hinzufügen einer neuen Postleitzahl",
  EditingZipCode: "Ausgabe der Postleitzahl",
  NewZipCode: "Neue Reißverschlusse",
  Exposed: "Ausgesetzt",
  AcceptedForRealization: "Im Ausland",
  Loaded: "Geladen",
  Completed: "Erkannte",
  TimeToUnload: "Zeit zum Entladen",
  AddToFollowed: "Hinzufügen zu folgen",
  ButtonIsDisabled: "Der Knopf wird ausgeschaltet",
  AcceptedTransportOrdersNumber: "Anzahl der anerkannten Bestellungen",
  OrderedTransportOrdersNumber: "Anzahl der Bestellungen bestellt",
  ParticipatedAuctionsNumber: "Die Anzahl der Auktionen, an denen die Teilnahme durchgeführt wurde",
  WinAuctionsNumber: "Anzahl der Auktionen gewonnen",
  EditTransportOrderEnumerator: "Bearbeiten der Bestellung Enumerator",
  TransportOrderEnumerator: "Bestellnummerer",
  ToTheEndOfTheAuctionRemained: "Es blieb bis zum Ende der Auktion",
  AuctionWillTakePlaceIn: "Die Auktion beginnt mit",
  New: "Neu",
  AuthorizedPickupPersonPhoneNumber: "Telefonnummer der Person/Firma, die zur Abholung autorisiert ist",
  AuthorizedPickupPersonPhoneNumberPlaceholder: "an die Person, die zugelassen wurde",
  TransportOrdersManagement_Columns_AuthorizedPickupPersonPhoneNumber: "Kontaktieren Sie das Telefon an eine Person, die zur Abholung befugt ist",
  DifferentCompany: "Eine andere Firma",
  Expand: "Expandieren",
  Fold: "Zusammenbruch",
  ElectricInvoice: "Für elektronische Rechnungen",
  InvoiceSendToaddress: "Bitte senden Sie die Rechnung an die Adresse",
  YouWin: "Du gewinnst",
  DownloadPdf: "PDF Herunterladen",
  ShowPdf: "PDF anzeigen",
  Order: "Bestellung",
  AddingNewDriver: "Hinzufügen eines neuen Fahrers",
  EditingDriver: "Fahrer Edition",
  Settings: "Einstellungen",
  Ongoing: "Laufend",
  DashboardOngoing: "Laufend",
  DashboardFinishedWithSuccess: "Erfolgreich abgeschlossen",
  DashboardFinishedWithoutSuccess: "Beendet ohne Erfolg",
  WithMyParticipation: "Wir bieten",
  Canceled: "Abgesagt",
  Finished: "Beendet",
  Won: "Gewinnt",
  Lost: "Verlierer",
  NotWon: "Nicht gewinnen",
  All: "Alle",
  Accepted: "Akzeptiert",
  OrderSuffix: "Suffix -Bestellnummer",
  DataForInvoiceTransportOrder: "Daten zum Senden einer Rechnung",
  DataForTransportOrder: "Daten für die Bestellung",
  FullName: "Vollständiger Name",
  InvoiceEmailAddress: "E-Mail-Adresse zum Senden von Rechnungen",
  Date: "Datum",
  GoToTheEvent: "Zu der Veranstaltung gehen",
  Draft: "Entwurf",
  Ended: "Beendet",
  SuccessfullyCreatedAuction: "Eine neue Auktion wurde erfolgreich erstellt",
  SuccessfullyModificatedAuction: "Die Auktion wurde erfolgreich geändert",
  SuccessfullyCreatedOrder: "Eine neue Bestellung wurde erfolgreich erstellt",
  SuccessfullyModificatedOrder: "Die Bestellung wurde erfolgreich geändert",
  SuccessfullyCreatedUser: "Ein neuer Benutzer wurde erfolgreich erstellt",
  SuccessfullyModificatedUser: "Der Benutzer wurde erfolgreich geändert",
  SuccessfullyCreatedDepartment: "Eine neue Niederlassung wurde erfolgreich erstellt",
  SuccessfullyModificatedDeparment: "Der Zweig wurde erfolgreich geändert",
  SuccessfullyCreatedFacility: "Eine neue Pflanze wurde erfolgreich erstellt",
  SuccessfullyModificatedFacility: "Die Anlage wurde erfolgreich verändert",
  SuccessfullyCreatedCompany: "Ein neues Unternehmen wurde erfolgreich erstellt",
  SuccessfullyModificatedCompany: "Das Unternehmen wurde erfolgreich geändert",
  SuccessfullyCreatedAddress: "Eine neue Adresse wurde erfolgreich erstellt",
  SuccessfullyModificatedAddress: "Die Adresse wurde erfolgreich geändert",
  SuccessfullyCreatedRoute: "Eine neue Route wurde erfolgreich erstellt",
  SuccessfullyModificatedRoute: "Die Route wurde erfolgreich geändert",
  SuccessfullyCreatedVehicle: "Ein neues Fahrzeug wurde erfolgreich erstellt",
  SuccessfullyModificatedVehicle: "Das Fahrzeug wurde erfolgreich modifiziert",
  SuccessfullyCreatedZipCode: "Eine neue Postleitzahl wurde erfolgreich erstellt",
  SuccessfullyModificatedZipCode: "Postleitzahl wurde erfolgreich geändert",
  SuccessfullyCreatedDefaultPrice: "Ein neuer Standardpreis wurde erfolgreich erstellt",
  SuccessfullyModificatedDefaultPrice: "Der Standardpreis wurde erfolgreich geändert",
  SuccessfullyModificatedHTMLTemplate: "Die HTML -Vorlage wurde erfolgreich geändert",
  SuccessfullyCreatedCarType: "Eine neue Art von Fahrzeug wurde erfolgreich erstellt",
  SuccessfullyModificatedCarType: "Der Fahrzeugtyp wurde erfolgreich geändert",
  SuccessfullyCreatedCurrency: "Eine neue Währung wurde erfolgreich hinzugefügt",
  SuccessfullyModificatedCurrency: "Die Währung wurde erfolgreich geändert",
  SuccessfullyCreatedDriver: "Ein neuer Fahrer wurde erfolgreich hinzugefügt",
  SuccessfullyModificatedDriver: "Der Fahrer wurde erfolgreich geändert",
  CanceledTransportOrderStatus: "Abgesagt",
  AcceptedTransportOrderStatus: "Im Ausland",
  DashboardPlacedTransportOrderStatus: "Ausgegeben",
  DashboardAcceptedTransportOrderStatus: "Im Ausland",
  CompletedTransportOrderStatus: "Erkannte",
  DashboardCompletedTransportOrderStatus: "Erkannte",
  ConfigurableDiagramCompletedTransportOrders: "Wurde realisiert",
  LoadedTransportOrderStatus: "Geladen",
  DashboardLoadedTransportOrderStatus: "Geladen",
  CanceledAuctionStatus: "Abgesagt",
  LiveAuctionStatus: "Laufend",
  Status: "Status",
  IsParticipant: "Wir bieten",
  IsWon: "Sie gewinnen",
  WithSuccess: "Erfolgreich",
  WithoutSuccess: "Ohne Erfolg",
  WithoutOffer: "Kein Angebot",
  EmailSubject: "E -Mail -Thema",
  HomePage: "Heim",
  Preview: "Vorschau",
  BidingByHHAllowed: "Erlaubnis, durch einen HH -Mitarbeiter zu geben",
  NotGiven: "Nicht angegeben",
  LackOfInformations: "Informationsmangel",
  LackOfData: "Keine Daten",
  EndedAuctionStatus: "Beendet",
  Placed: "Ausgesetzt",
  PlacedTransportOrderStatus: "Ausgesetzt",
  YouAreWinning: "Du gewinnst",
  YouAreLosing: "Du verlierst",
  AddingNewTransportOrderBasedOnAuction: "Hinzufügen einer Bestellung auf der Basis einer Auktion hinzufügen",
  ClosedAuctionStatus: "Fertig",
  InJanuary: "Im Januar",
  InFebruary: "Im Februar",
  InMarch: "Im März",
  InApril: "Im April",
  InMay: "Im Mai",
  InJune: "Im Juni",
  InJuly: "Im Juli",
  InAugust: "Im August",
  InSeptember: "Im September",
  InOctober: "Im Oktober",
  InNovember: "Im November",
  InDecember: "Im Dezember",
  ForPublication: "Zur Veröffentlichung",
  Dashboard: "Hauptbildschirm",
  AuctionHasBeen: "Die Auktion blieb",
  CurrentAuctionStatus: "Aktueller Status der Auktion",
  InsideYourClipboardIsLocatedPlaceholder: "Es gibt einen Platzhalter in Ihrer Lagerung",
  PasteItIntoAppropriatePlaceInsideTheTemplate: "Fügen Sie es in die richtige Stelle auf der Vorlage ein",
  AuctionPage_AuctionCancelation: "Auktion abbrechen",
  AuctionPage_CancelAuction: "Abbrechen der Auktion",
  AuctionPage_CancelAuctionDialogDescription: "Geben Sie den Grund für die Stornierung der Auktion ein, die an alle Bieter gesendet wird",
  AuctionPage_ProviceReason: "Geben Sie den Grund ein ...",
  AuctionPage_CancelAuctionDialogErrorMessage: "Der Wert kann nicht leer sein",
  CopyThePhoneNumber: "Kopieren Sie die Telefonnummer",
  SuccessfullyAddedPhoneNumberToTheClipboard: "Die Telefonnummer wurde erfolgreich in die Zwischenablage hinzugefügt",
  Notifications: "Benachrichtigungen",
  PageNotFound: "Die Seiten wurden nicht gefunden",
  TheSiteDoesNotExistOrHasBeenRelocated: "Die Website existiert nicht oder wurde bewegt",
  ReturnToTheHomePage: "Zurück zur Hauptseite",
  Realized: "Realisiert",
  AddingNewAuctionBasedOnAuction: "Hinzufügen von Auktionen basierend auf der Auktion",
  InternalNote: "Interne Note",
  WriteInternalNote: "Schreiben Sie eine interne Notiz",
  PhoneNumberWasntProvided: "Die Telefonnummer wurde nicht bereitgestellt",
  MySettings_Auctions: "Auktionen",
  MySettings_Orders: "Transportaufträge",
  MySettings_System: "System",
  HtmlTemplatesManagement_Columns_Area: "Bereich",
  TodayAreEnding: "Heute endet es",
  ForTodayYouHavePlaced: "Gegenwärtig werden Sie ausgestellt",
  DataFix: "Datum festlegen",
  From: "Aus",
  To: "Runter",
  DeleteAuction: "Entfernen Sie die Auktion",
  InvitationToPlatform: "Einladung zur Plattform",
  SuccessfullySendInvitationToPlatform: "Eine Einladung zur Plattform wurde erfolgreich gesendet",
  AcceptRodo: "Ich akzeptiere die Regeln der DSGVO",
  ReadConditions: "Ich habe die Bedingungen für das Laden, Entladen und Speichern von Zellbetonprodukten H+H Polska kennengelernt",
  ReadPrinciples: "Ich habe die Regeln für die sichere Beladung von Produkten an Empfängerautos kennengelernt",
  MyCompany: "Meine Firma",
  ClickHereToReadTheConditions: "Klicken Sie hier, um die Bedingungen zu lesen",
  ClickHereToReadThePrinciples: "Klicken Sie hier, um die Regeln zu lesen",
  AddNew_Feminine: "Fügen Sie eine neue hinzu",
  AddNew_Masculine: "Neue hinzufügen",
  AddNewAuction: "Fügen Sie neue Auktionen hinzu",
  AddNewOrder: "Fügen Sie eine neue Bestellung hinzu",
  AuctionHasBeenCanceledBy: "Die Auktion wurde von abgesagt von",
  InformationsAboutTheAuction: "Auktionsinformationen",
  ActivateCompany: "Unternehmensaktivierung",
  IsActive: "Oder aktiv",
  ReportCompany: "Das Unternehmen melden",
  TransportOrderDialog_Content: "Fügen Sie die Aufmerksamkeit auf die Bestellung hinzu.",
  TransportOrderDialog_ErrorMessage: "Die Nachricht kann nicht leer sein.",
  TransportOrderDialog_ProvideMessage: "Nachricht schreiben...",
  TransportOrderDialog_AddNote: "Fügen Sie die Aufmerksamkeit auf die Bestellung hinzu",
  AttachNecessaryAttachmentsListedInFollowingList: "Fügen Sie die in der folgenden Liste aufgeführten erforderlichen Anhänge bei",
  Attachments: "Anhänge",
  InProgressOfDateAndAcceptanceVerification: "Während des Prozesses der Datenüberprüfung und -akzeptanz",
  AfterPositiveConsiderationYouWillBeNotifiedByEmailAboutThePossibilityOfSubmittingOfferts: "Nach einer positiven Überlegung werden Sie per E -Mail über die Möglichkeit des Einreichens von Angeboten informiert",
  Bidded: "Hat Bid",
  LinkToTheAuction: "Link zur Auktion",
  InformationObligation: "Informationsverpflichtung",
  ConsentToTheProcessingOfPersonalData: "Datenverarbeitungsvereinbarung",
  Cancel: "Abbestellen",
  Save: "Speichern",
  AddCompany_AttachmentInformation_License: "Lizenzen für den Straßenverkehr im Gebiet der Mediation beim Transport von Dingen",
  AddCompany_AttachmentInformation_Permission: "Erlaubnis, den Träger zu üben",
  AddCompany_AttachmentInformation_CarrierInsurance: "Trägerversicherung",
  AddCompany_AttachmentInformation_ForwarderInsurance: "SPPSCEPORT OC -Versicherung",
  SuccessfullyDownloadedAttachment: "Ein Anhang wurde erfolgreich heruntergeladen",
  SuccessfullyRemovedAttachment: "Ein Anhang wurde erfolgreich entfernt",
  Automatically: "Automatisch",
  AutomaticallyGenerated: "Automatisch erzeugt",
  AutomaticallyExposed: "Automatisch ausgegeben",
  TransportOrderPage_CancelOrderDialogErrorMessage: "Der Wert kann nicht leer sein",
  LastMonth: "Im vergangenen Monat",
  TransportOrderPage_CancelTransportOrderDialogDecription: "Geben Sie den Grund für die Stornierung der Bestellung ein, die an Benutzer gesendet wird",
  LastWeek: "Letzte Woche",
  TransportOrderPage_OrderCancelation: "Stornierung des Transportauftrags",
  LastYear: "Letztes Jahr",
  TransportOrderPage_ProviceReason: "Geben Sie den Grund ein",
  FieldRequired: "Pflichtfeld",
  DriverPhoneNumber: "Telefonnummer des Fahrers",
  OnlyMyFacility: "Nur meine Pflanze",
  AnotherCompany: "Ein anderes Unternehmen",
  ImplementationDetails: "Details der Benachrichtigung",
  DeclaredLoadingRangeDateTime: "Deklarierter Bereich des Datums und der Uhrzeit des Ladens",
  CurrentLoadingDateTime: "Aktuelles Datum und Uhrzeit des Ladens",
  LoadingDetails: "Ladedetails",
  DashboardActions: "Auktion",
  CurrentlyYouHave: "Derzeit haben Sie",
  RealizedInYears: "Realisiert in den Jahren",
  ConfigurableDiagramDataModificationTitle: "Ändern Sie den auf dem Diagramm angezeigten Datenbereich",
  DownloadRaport: "Laden Sie den Bericht herunter",
  ClickToDisplayDataFromThisPeriod: "Klicken Sie hier, um die Daten dieses Zeitraums anzuzeigen",
  DeclaredUnloadingRangeDateTime: "Deklarierte Date und Uhrzeit des Entladens",
  RealizedDetails: "Implementierungsdetails",
  Code: "Code",
  TransportCode: "Transportcode",
  Reports: "Berichte",
  ExportNav: "Exportieren von NAV",
  Number: "Nummer",
  CreatedBy: "Erstellt von",
  CreatedOn: "Erstelldatum",
  Generate: "Generieren"
};
exports.default = _default;