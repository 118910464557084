"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var actionTypes = {
  LANGUAGE_CHANGE: 'LANGUAGE_CHANGE'
};
var _default = actionTypes;
exports.default = _default;