const fieldNames = {
  loginForm: {
    emailOrLogin: 'emailOrLogin',
    password: 'password',
  },
  resetPasswordForm: {
    email: 'email',
  },
  setNewPasswordForm: {
    email: 'email',
    newPassword: 'newPassword',
    repeatPassword: 'repeatPassword',
  },
  myProfileUserInformationForm: {
    login: 'login',
    avatar: 'avatar',
  },
  userPasswordChangeForm: {
    currentPassword: 'currentPassword',
    newPassword: 'newPassword',
    repeatPassword: 'repeatPassword',
  },
  userInformationForm: {
    login: 'login',
    email: 'email',
    firstName: 'firstName',
    lastName: 'lastName',
    phoneNumber: 'phoneNumber',
    facility: 'facility',
    company: 'company',
    department: 'department',
    isBanned: 'isBanned',
  },
  htmlTemplateInformationForm: {
    name: 'name',
    description: 'description',
    content: 'content',
    emailContent: 'emailContent',
    smsContent: 'smsContent',
    emailSubject: 'emailSubject',
  },
  departmentInformationForm: {
    name: 'name',
    auctionEnumerator: 'auctionEnumerator',
    auctionDraftEnumerator: 'auctionDraftEnumerator',
    editAuctionDraftEnumerator: 'editAuctionDraftEnumerator',
    editAuctionEnumerator: 'editAuctionEnumerator',
    transportOrderEnumerator: 'transportOrderEnumerator',
    editTransportOrderEnumerator: 'editTransportOrderEnumerator',
    fullName: 'fullName',
    zipCode: 'zipCode',
    city: 'city',
    street: 'street',
    invoiceDestinationName: 'invoiceDestinationName',
    invoiceDestinationZipCode: 'invoiceDestinationZipCode',
    invoiceDestinationCity: 'invoiceDestinationCity',
    invoiceDestinationStreet: 'invoiceDestinationStreet',
    emailInvoiceAddress: 'emailInvoiceAddress',
    nip: 'nip',
  },
  companyInformationForm: {
    name: 'name',
    address: 'address',
    zipCode: 'zipCode',
    city: 'city',
    nip: 'nip',
    phoneNumber: 'phoneNumber',
    street: 'street',
    regon: 'regon',
    isBlocked: 'isBlocked',
    bidingByHHAllowed: 'bidingByHHAllowed',
    isActive: 'isActive',
    companyAttachments: 'companyAttachments',
  },
  carTypeInformationForm: {
    name: 'name',
  },
  carInformationForm: {
    registerNumber: 'registerNumber',
    comment: 'comment',
    carType: 'carType',
    company: 'company',
  },
  facilityInformationForm: {
    name: 'name',
    address: 'address',
    zipCode: 'zipCode',
    city: 'city',
    email: 'email',
    phoneNumber: 'phoneNumber',
    department: 'department',
    isNewAddress: 'isNewAddress',
  },
  routeInformationForm: {
    zipCodeFrom: 'zipCodeFrom',
    isNewZipCodeFrom: 'isNewZipCodeFrom',
    zipCodeTo: 'zipCodeTo',
    isNewZipCodeTo: 'isNewZipCodeTo',
    distance: 'distance',
  },
  currencyInformationForm: {
    name: 'name',
    isDefault: 'isDefault',
  },
  auctionInformationForm: {
    startTime: 'startTime',
    endTime: 'endTime',
    principalPerson: 'principalPerson',
    facility: 'facility',
    loadingTimeTo: 'loadingTimeTo',
    loadingTimeFrom: 'loadingTimeFrom',
    loadingTimeFix: 'loadingTimeFix',
    loadingAddress: 'loadingAddress',
    unloadingTimeTo: 'unloadingTimeTo',
    unloadingTimeFrom: 'unloadingTimeFrom',
    unloadingTimeFix: 'unloadingTimeFix',
    unloadingAddress: 'unloadingAddress',
    authorizedPickupPerson: 'authorizedPickupPerson',
    authorizedPickupPersonPhoneNumber: 'authorizedPickupPersonPhoneNumber',
    distance: 'distance',
    pricePerKilometer: 'pricePerKilometer',
    startPrice: 'startPrice',
    currency: 'currency',
    capacity: 'capacity',
    loadingMethod: 'loadingMethod',
    unloadingMethod: 'unloadingMethod',
    additionalRequirements: 'additionalRequirements',
    auctionType: 'auctionType',
    subjects: 'subjects',
    isNewUnloadingAddress: 'isNewUnloadingAddress',
    isNewLoadingAddress: 'isNewLoadingAddress',
  },
  transportOrderInformationForm: {
    facility: 'facility',
    company: 'company',
    driver: 'driver',
    loadingTime: 'loadingTime',
    loadingAddress: 'loadingAddress',
    unloadingTime: 'unloadingTime',
    unloadingAddress: 'unloadingAddress',
    authorizedPickupPerson: 'authorizedPickupPerson',
    authorizedPickupPersonPhoneNumber: 'authorizedPickupPersonPhoneNumber',
    distance: 'distance',
    pricePerKilometer: 'pricePerKilometer',
    value: 'value',
    car: 'car',
    capacity: 'capacity',
    loadingMethodId: 'loadingMethodId',
    unloadingMethodId: 'unloadingMethodId',
    additionalRequirements: 'additionalRequirements',
    isNewDriver: 'isNewDriver',
    isNewCar: 'isNewCar',
    isNewLoadingAddress: 'isNewLoadingAddress',
    isNewUnloadingAddress: 'isNewUnloadingAddress',
    navNumber: 'navNumber',
    currency: 'currency',
    additionalNumber: 'additionalNumber',
    comment: 'comment',
    loadingTimeFrom: 'loadingTimeFrom',
    loadingTimeTo: 'loadingTimeTo',
    loadingTimeFix: 'loadingTimeFix',
    unloadingTimeFrom: 'unloadingTimeFrom',
    unloadingTimeTo: 'unloadingTimeTo',
    unloadingTimeFix: 'unloadingTimeFix',
  },
  newAddressInformationForm: {
    street: 'street',
    zipCode: 'zipCode',
    city: 'city',
  },
  newDriverInformationForm: {
    firstName: 'firstName',
    lastName: 'lastName',
    phoneNumber: 'phoneNumber',
    company: 'company',
  },
  newCarInformationForm: {
    registerNumber: 'registerNumber',
    carType: 'carType',
    comment: 'comment',
  },
  userPermissions: {
    ADMIN: 'ADMIN',
    DEP_ADMIN: 'DEP_ADMIN',
    EMPLOYEE: 'EMPLOYEE',
    LOGISTICIAN: 'LOGISTICIAN',
    DRIVER: 'DRIVER',
    PRICES_MANAGEMENT: 'PRICES_MANAGEMENT',
    ACTIVATE_COMPANY: 'ACTIVATE_COMPANY',
  },
  defaultPriceInformationForm: {
    flatRate: 'flatRate',
    minDistance: 'minDistance',
    maxDistance: 'maxDistance',
    facility: 'facility',
  },
  zipCodeForm: {
    zipCode: 'zipCode',
    city: 'city',
  },
  newZipCodeInformationForm: {
    zipCode: 'zipCode',
    city: 'city',
  },
  simpleAddUserForm: {
    email: 'email',
    firstName: 'firstName',
    lastName: 'lastName',
  },
};

export default fieldNames;
