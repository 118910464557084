import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import AuctionInformationAddForm from './Forms/AuctionInformationAddForm';
import AuctionsManagementSrv from './AuctionsManagementSrv';
import AuctionsManagementPagePreviewContainer from './AuctionsManagementPagePreviewContainer';

const propTypes = {
  match: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSave: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  showSubjects: PropTypes.bool.isRequired,
  auctionTypes: PropTypes.array.isRequired,
  loadingMethods: PropTypes.array.isRequired,
  unloadingMethods: PropTypes.array.isRequired,
  onSubjectsChange: PropTypes.func.isRequired,
  onDistanceChange: PropTypes.func.isRequired,
  onPricePerKilometerChange: PropTypes.func.isRequired,
  onStartPriceChange: PropTypes.func.isRequired,
  onResetPrices: PropTypes.func.isRequired,
  onGetDefaultPrices: PropTypes.func.isRequired,
  routes: PropTypes.array.isRequired,
  zipCodes: PropTypes.array.isRequired,
};

const defaultProps = {};

class AuctionsManagementAddBasedOnAuctionPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSpinner: false,
      facilities: [],
      currencies: [],
      auctionSubjects: [],
      principalPersons: [],
      addresses: [],
      initialValues: {},
    };
  }

  componentDidMount() {
    this.setState({ showSpinner: true });

    this.props.onGetDefaultPrices();

    Promise.all([
      AuctionsManagementSrv.getForAddBasedOnAuction(
        this.props.match.params.auctionId,
      ),
      AuctionsManagementSrv.getFacilitiesAsSelectItems(),
      AuctionsManagementSrv.getAuctionSubjectsAsSelectItems(),
      AuctionsManagementSrv.getPrincipalPersonsAsSelectItems(),
      AuctionsManagementSrv.getAddressesAsSelectItem(),
      AuctionsManagementSrv.getCurrenciesAsSelectItem(),
    ])
      .then(
        ([
          initialValues,
          facilities,
          auctionSubjects,
          principalPersons,
          addresses,
          currencies,
        ]) => {
          this.setState({
            initialValues,
            facilities,
            auctionSubjects,
            principalPersons,
            addresses,
            currencies,
          });
        },
      )
      .finally(() => this.setState({ showSpinner: false }));
  }

  render() {
    const {
      onSubmit,
      onSubmitSave,
      history,
      showSubjects,
      loadingMethods,
      unloadingMethods,
      auctionTypes,
      onSubjectsChange,
      onDistanceChange,
      onPricePerKilometerChange,
      onStartPriceChange,
      onResetPrices,
      routes,
      zipCodes,
    } = this.props;

    const {
      showSpinner,
      initialValues,
      facilities,
      currencies,
      auctionSubjects,
      principalPersons,
      addresses,
    } = this.state;

    return (
      <>
        <BreadcrumbsContainer
          links={[
            {
              routePath: '/',
              label: lang.labels.HomePage(),
            },
            {
              routePath: '/auctions-management',
              label: lang.labels.Auctions(),
            },
            {
              routePath: '/auctions-management/add',
              label: `${lang.labels.AddingNewAuctionBasedOnAuction()}: ${initialValues &&
                initialValues.number}`,
            },
          ]}
        />
        <FormPreviewSectionContainer>
          <FormCardWrapper>
            <div className={showSpinner ? 'fox_spinner' : ''}>
              <AuctionInformationAddForm
                onSubmit={onSubmit}
                onSubmitSave={onSubmitSave}
                history={history}
                facilities={facilities}
                currencies={currencies}
                loadingMethods={loadingMethods}
                unloadingMethods={unloadingMethods}
                auctionTypes={auctionTypes}
                auctionSubjects={auctionSubjects}
                showSubjects={showSubjects}
                principalPersons={principalPersons}
                initialValues={initialValues}
                addresses={addresses}
                onSubjectsChange={onSubjectsChange}
                onDistanceChange={onDistanceChange}
                onPricePerKilometerChange={onPricePerKilometerChange}
                onStartPriceChange={onStartPriceChange}
                onResetPrices={onResetPrices}
                routes={routes}
                zipCodes={zipCodes}
              />
            </div>
          </FormCardWrapper>
          <AuctionsManagementPagePreviewContainer />
        </FormPreviewSectionContainer>
      </>
    );
  }
}

AuctionsManagementAddBasedOnAuctionPage.propTypes = propTypes;
AuctionsManagementAddBasedOnAuctionPage.defaultProps = defaultProps;

export default AuctionsManagementAddBasedOnAuctionPage;
