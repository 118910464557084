"use strict";

function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireWildcard(require("react"));

var _reactRedux = require("react-redux");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _FontAwesomeIcon = _interopRequireDefault(require("../components/commons/FontAwesomeIcon/FontAwesomeIcon"));

var _domMethods = require("../utils/domMethods");

var _ToastModule = _interopRequireDefault(require("./Toast.module.scss"));

var _CloseButton = _interopRequireDefault(require("./CloseButton"));

var _toastUtils = require("./toastUtils");

var _toastrActions = require("./toastrActions");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var propTypes = {
  children: _propTypes.default.string.isRequired,
  type: _propTypes.default.string,
  closeTime: _propTypes.default.number,
  id: _propTypes.default.string.isRequired
};
var defaultProps = {
  type: '',
  closeTime: 20000
};

var Toast = function Toast(_ref) {
  var id = _ref.id,
      children = _ref.children,
      type = _ref.type,
      closeTime = _ref.closeTime;

  var _useState = (0, _react.useState)((0, _toastUtils.getToastProperties)(type)),
      _useState2 = _slicedToArray(_useState, 1),
      toastProperties = _useState2[0];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = _slicedToArray(_useState3, 2),
      toastIsTouched = _useState4[0],
      setToastIsTouched = _useState4[1];

  var _useState5 = (0, _react.useState)(false),
      _useState6 = _slicedToArray(_useState5, 2),
      showToast = _useState6[0],
      setShowToast = _useState6[1];

  var _useState7 = (0, _react.useState)({}),
      _useState8 = _slicedToArray(_useState7, 2),
      toastStyles = _useState8[0],
      setToastStyles = _useState8[1];

  var animationDuration = 500;
  var toastRef = (0, _react.useRef)();
  var dispatch = (0, _reactRedux.useDispatch)();
  var closeToast = (0, _react.useCallback)(function () {
    dispatch((0, _toastrActions.closeToastr)(id));
  }, id);
  var toastClassName = (0, _classnames.default)(_ToastModule.default.toast, _ToastModule.default["background_".concat(toastProperties.color)], _defineProperty({}, _ToastModule.default.visible, showToast));
  var iconClassName = (0, _classnames.default)(_ToastModule.default.icon, _ToastModule.default["icon_".concat(toastProperties.color)]);
  (0, _react.useEffect)(function () {
    setTimeout(function () {
      setShowToast(true);
    }, 50);
  }, []);
  (0, _react.useEffect)(function () {
    var toast = toastRef.current;
    setToastStyles(function (prevStyles) {
      return _objectSpread({}, prevStyles, {
        height: "".concat((0, _domMethods.getElementHeight)(toast), "px"),
        padding: (0, _domMethods.getElementPropertyValueString)(toast, 'padding')
      });
    });
  }, [_react.useRef]);

  var onClose = function onClose() {
    setToastStyles(function (prevStyles) {
      return _objectSpread({}, prevStyles, {
        height: 0,
        padding: 0
      });
    });
    setShowToast(false);
    setTimeout(function () {
      closeToast();
    }, animationDuration);
  };

  var handleMouseEnter = function handleMouseEnter() {
    setToastIsTouched(true);
  };

  var handleMouseLeave = function handleMouseLeave() {
    setToastIsTouched(false);
  };

  return _react.default.createElement("div", {
    className: toastClassName,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    style: toastStyles,
    ref: toastRef
  }, _react.default.createElement("div", {
    className: _ToastModule.default.row
  }, _react.default.createElement("div", {
    className: _ToastModule.default.column
  }, _react.default.createElement("div", {
    className: _ToastModule.default.icon_circle
  }, _react.default.createElement(_FontAwesomeIcon.default, {
    icon: toastProperties.icon,
    title: type,
    className: iconClassName
  }))), _react.default.createElement("div", {
    className: _ToastModule.default.text_container
  }, children)), _react.default.createElement(_CloseButton.default, {
    closeTime: closeTime,
    onClose: onClose,
    toastIsTouched: toastIsTouched,
    counterDelay: animationDuration
  }));
};

Toast.propTypes = propTypes;
Toast.defaultProps = defaultProps;
var _default = Toast;
exports.default = _default;