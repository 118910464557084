"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _validationMessages = _interopRequireDefault(require("./translations/validationMessages.pl"));

var _validationMessages2 = _interopRequireDefault(require("./translations/validationMessages.en"));

var _validationMessages3 = _interopRequireDefault(require("./translations/validationMessages.de"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // File was autogenerated by tools/generateLabels.js script


var _default = {
  pl: _validationMessages.default,
  en: _validationMessages2.default,
  de: _validationMessages3.default
};
exports.default = _default;