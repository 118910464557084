"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // File was autogenerated by tools/generateLabels.js script

var _default = {
  AccessDenied: "Lack of access",
  ValueRequired: "The value is required"
};
exports.default = _default;