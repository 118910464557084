"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var iconsLocation = './svgs';
var lightIconsPath = "".concat(iconsLocation, "/light");
var solidIconsPath = "".concat(iconsLocation, "/solid");
var regularIconsPath = "".concat(iconsLocation, "/regular");
var duotoneIconsPath = "".concat(iconsLocation, "/duotone");
var lightIcons = {
  unfilledStar: "".concat(lightIconsPath, "/star"),
  unfilledClock: "".concat(lightIconsPath, "/clock")
};
var solidIcons = {
  filledStar: "".concat(solidIconsPath, "/star"),
  plus: "".concat(solidIconsPath, "/plus"),
  solidEdit: "".concat(solidIconsPath, "/pen"),
  solidDownload: "".concat(solidIconsPath, "/download"),
  solidCheck: "".concat(solidIconsPath, "/check"),
  solidExclamation: "".concat(solidIconsPath, "/exclamation"),
  solidInfo: "".concat(solidIconsPath, "/info"),
  solidBell: "".concat(solidIconsPath, "/bell")
};
var regularIcons = {
  check: "".concat(regularIconsPath, "/check"),
  cross: "".concat(regularIconsPath, "/times"),
  copy: "".concat(regularIconsPath, "/copy"),
  sad: "".concat(regularIconsPath, "/frown"),
  happy: "".concat(regularIconsPath, "/grin-wink")
};
var duotoneIcons = {
  document: "".concat(duotoneIconsPath, "/file"),
  editedDocument: "".concat(duotoneIconsPath, "/file-alt"),
  arrow: "".concat(duotoneIconsPath, "/arrow-alt-right"),
  permissions: "".concat(duotoneIconsPath, "/user-shield"),
  template: "".concat(duotoneIconsPath, "/file-alt"),
  clock: "".concat(duotoneIconsPath, "/clock"),
  placeholder: "".concat(duotoneIconsPath, "/paste"),
  plusMark: "".concat(duotoneIconsPath, "/map-marker-plus"),
  checkMark: "".concat(duotoneIconsPath, "/map-marker-check"),
  htmlDocument: "".concat(duotoneIconsPath, "/file-code"),
  mainAdmin: "".concat(duotoneIconsPath, "/users-crown"),
  departmentAdmin: "".concat(duotoneIconsPath, "/users"),
  employee: "".concat(duotoneIconsPath, "/user-edit"),
  logistic: "".concat(duotoneIconsPath, "/user-tie"),
  pricesManagement: "".concat(duotoneIconsPath, "/money-check-edit-alt"),
  address: "".concat(duotoneIconsPath, "/map-marked-alt"),
  dictionary: "".concat(duotoneIconsPath, "/book"),
  department: "".concat(duotoneIconsPath, "/city"),
  blocked: "".concat(duotoneIconsPath, "/ban"),
  zipCode: "".concat(duotoneIconsPath, "/map-marker-alt"),
  trash: "".concat(duotoneIconsPath, "/trash-alt"),
  auction: "".concat(duotoneIconsPath, "/gavel"),
  route: "".concat(duotoneIconsPath, "/route"),
  truck: "".concat(duotoneIconsPath, "/truck-container"),
  userData: "".concat(duotoneIconsPath, "/id-card"),
  money: "".concat(duotoneIconsPath, "/money-bill-wave"),
  information: "".concat(duotoneIconsPath, "/info-circle"),
  user: "".concat(duotoneIconsPath, "/user"),
  chevronUp: "".concat(duotoneIconsPath, "/chevron-up"),
  chevronDown: "".concat(duotoneIconsPath, "/chevron-down"),
  arrowRight: "".concat(duotoneIconsPath, "/arrow-right"),
  arrowLeft: "".concat(duotoneIconsPath, "/chevron-left"),
  coin: "".concat(duotoneIconsPath, "/coin"),
  coins: "".concat(duotoneIconsPath, "/coins"),
  currencies: "".concat(duotoneIconsPath, "/coins"),
  moneySack: "".concat(duotoneIconsPath, "/sack-dollar"),
  password: "".concat(duotoneIconsPath, "/lock-alt"),
  letter: "".concat(duotoneIconsPath, "/envelope-open-text"),
  phone: "".concat(duotoneIconsPath, "/mobile-android-alt"),
  loadingMark: "".concat(duotoneIconsPath, "/map-marker-plus"),
  deliverMark: "".concat(duotoneIconsPath, "/map-marker-check"),
  vehicle: "".concat(duotoneIconsPath, "/shipping-fast"),
  driver: "".concat(duotoneIconsPath, "/user-cowboy"),
  fastTruck: "".concat(duotoneIconsPath, "/shipping-fast"),
  timeTruck: "".concat(duotoneIconsPath, "/shipping-timed"),
  order: "".concat(duotoneIconsPath, "/clipboard-prescription"),
  fileData: "".concat(duotoneIconsPath, "/file-alt"),
  loadingVehicle: "".concat(duotoneIconsPath, "/forklift"),
  email: "".concat(duotoneIconsPath, "/at"),
  facility: "".concat(duotoneIconsPath, "/industry-alt"),
  company: "".concat(duotoneIconsPath, "/building"),
  calendar: "".concat(duotoneIconsPath, "/calendar-exclamation"),
  smile: "".concat(duotoneIconsPath, "/smile-wink"),
  longRightArrow: "".concat(duotoneIconsPath, "/long-arrow-alt-right"),
  bell: "".concat(duotoneIconsPath, "/bell"),
  documentWithData: "".concat(duotoneIconsPath, "/file-invoice"),
  download: "".concat(duotoneIconsPath, "/download"),
  approval: "".concat(duotoneIconsPath, "/user-check"),
  dashboard: "".concat(duotoneIconsPath, "/columns"),
  comment: "".concat(duotoneIconsPath, "/file-signature"),
  settings: "".concat(duotoneIconsPath, "/cogs")
};

var icons = _objectSpread({}, lightIcons, {}, solidIcons, {}, regularIcons, {}, duotoneIcons);

var _default = icons;
exports.default = _default;