import { connect } from 'react-redux';
import get from 'lodash/get';
import { getTransportOrderPreviewLink } from 'common/links';
import TransportOrdersManagementAddPage from './TransportOrdersManagementAddPage';
import { addAsync } from './actions';
import { getDefaultPrices } from '../auctions-management/actions';

const mapStateToProps = state => {
  return {
    userFacilityInstanceId: get(state, 'auth.currentUser.facilityInstanceId'),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: model =>
      addAsync(model).then(res =>
        ownProps.history.push(getTransportOrderPreviewLink(res.data)),
      ),
    onGetDefaultPrices: () => dispatch(getDefaultPrices()),
  };
};

const TransportOrdersManagementAddPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransportOrdersManagementAddPage);

export default TransportOrdersManagementAddPageContainer;
