import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import persistState from 'redux-localstorage';
import get from 'lodash/get';
import gridNames from 'common/gridNames';
import set from 'lodash/set';
import rootReducer from './rootReducer';
import signalRMiddleware from '../hub-connection/middleware';

const actionSanitizer = action =>
  action.type === 'STORE_BIG_STATIC_DICTIONARIES' && action.data
    ? { ...action, data: '<<LONG_BLOB>>' }
    : action;

const stateSanitizer = state =>
  get(state, 'dictionary.static.zipCodes')
    ? {
        ...state,
        dictionary: {
          ...state.dictionary,
          static: {
            ...state.dictionary.static,
            zipCodes: '<<LONG_BLOB>>',
            routes: '<<LONG_BLOB>>',
            zipCodeCodes: '<<LONG_BLOB>>',
          },
        },
      }
    : state;

function getPathsForGridSettings() {
  return Object.keys(gridNames).map(x => `${x}.columnsInfo`);
}

function slicer(paths) {
  return state => {
    const subset = {};
    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];
      const extractedValue = get(state, path);
      set(subset, path, extractedValue);
    }

    return subset;
  };
}

function configureStore() {
  // const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
  const composeEnhancers = composeWithDevTools({
    actionSanitizer,
    stateSanitizer,
  });

  const store = createStore(
    rootReducer,
    {},
    composeEnhancers(
      applyMiddleware(thunk, signalRMiddleware),
      persistState(['auth', ...getPathsForGridSettings()], {
        slicer,
      }),
    ),
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./rootReducer', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
}

const store = configureStore();

export default store;
