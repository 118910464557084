import React, { memo, useContext } from 'react';
import Row from 'hh-shared/dist/components/layout/Row';
import Column from 'hh-shared/dist/components/layout/Column';
import { diagramSelector, keysSelector } from 'dashboard/selectors';
import { useSelector } from 'react-redux';
import Bar from './Bar';
import RangeConfigurationButtons from './RangeConfigurationButtons';
import { getFormValues } from 'redux-form';
import lang from 'hh-shared/dist/language/services/languageService';
import get from 'lodash/get';
import icons from 'hh-shared/dist/consts/icons';
import GreyTitle from '../../GreyTitle';
import DashboardButton from '../../DashboardButton';
import ChartHeader from 'dashboard/ChartHeader';
import ReportSrv from 'dashboard/ReportsSrv';
import SpinnerContext from 'common/SpinnerContext';
import { useCurrentUser } from 'common/useCurrentUser';
import permissions from 'common/permissions';

const propTypes = {};

const defaultProps = {};

const diagramPickerSelector = getFormValues('diagramPicker');

const calculateData = (
  { yearWeeks, yearMonths, yearMonthDays },
  { year, month, week, type },
) => {
  if (!year || !month || !week) {
    return [];
  }

  const yearSelector = `${year.id}`;

  switch (type) {
    case 'year':
      return yearMonths[yearSelector] || [];
    case 'month':
      const perYearM = yearMonthDays[yearSelector];
      return perYearM ? perYearM[month.id] : [];
    case 'week':
      const perYearW = yearWeeks[yearSelector];
      return perYearW ? perYearW[week.id] : [];
    default:
      return [];
  }
};

const calculateAxisBottom = ({ type }) => {
  switch (type) {
    case 'year':
      return { tickRotation: 45 };
    case 'month':
    case 'week':
    default:
      return {};
  }
};

const calculateSelectedRangeValues = (
  { countPerYear, countPerMonth, countPerWeek },
  { year, month, week },
) => {
  if (!year || !month || !week) {
    return undefined;
  }
  const yearSelector = `${year.id}`;

  return {
    yearCount: countPerYear[yearSelector],
    monthCount: get(countPerMonth[yearSelector], `[${month.id}]`) || 0,
    weekCount: get(countPerWeek[yearSelector], `[${week.id}]`) || 0,
  };
};

const getPickedTypeTranslation = ({
  type,
  year = {},
  month = {},
  week = {},
}) => {
  switch (type) {
    case 'week':
      return week.name;
    case 'month':
      return month.name;
    case 'year':
      return year.name;
    default:
      return '';
  }
};

function ConfigurableDiagram() {
  const { hasOneOf } = useCurrentUser();

  const { setShowSpinner } = useContext(SpinnerContext);
  const picker = useSelector(state => diagramPickerSelector(state)) || {};
  const diagram = useSelector(state => diagramSelector(state));
  const data = calculateData(diagram, picker);
  const keys = useSelector(state => keysSelector(state));
  const axisBottom = calculateAxisBottom(picker);

  const selectedRangeValues = calculateSelectedRangeValues(diagram, picker);
  const pickedTypeDescription = getPickedTypeTranslation(picker);

  const onDownloadRaport = () => {
    setShowSpinner(true);
    return ReportSrv.downloadCurrentYearReport().finally(() =>
      setShowSpinner(false),
    );
  };

  return (
    <Column>
      <Row className="justify-content-space-between align-items-start">
        <ChartHeader>
          {`${lang.labels.Realized()} ${pickedTypeDescription.toLowerCase()}`}
        </ChartHeader>
        {hasOneOf([permissions.ADMIN]) && (
          <DashboardButton
            title={lang.labels.DownloadRaport()}
            icon={icons.solidDownload}
            onClick={onDownloadRaport}
          >
            {lang.labels.DownloadRaport()}
          </DashboardButton>
        )}
      </Row>
      <Bar data={data} axisBottom={axisBottom} keys={keys} />
      <GreyTitle>{`${lang.labels.ConfigurableDiagramDataModificationTitle()}:`}</GreyTitle>
      <Row>
        <RangeConfigurationButtons selectedRangeValues={selectedRangeValues} />
      </Row>
    </Column>
  );
}

ConfigurableDiagram.propTypes = propTypes;
ConfigurableDiagram.defaultProps = defaultProps;

export default memo(ConfigurableDiagram);
