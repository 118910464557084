import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import NotificationBell from 'hh-shared/dist/components/commons/NotificationBell';
import lang from 'hh-shared/dist/language/services/languageService';
import ClickablePanel from 'hh-shared/dist/components/commons/ClickablePanel';
import AvatarPlaceholder from 'hh-shared/dist/components/commons/AvatarPlaceholder';
import Row from 'layout/Row';
import styles from './UserWidget.module.scss';
import LanguageBar from './LanguageBar';

const propTypes = {
  onLogOut: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatar: PropTypes.string,
};

const defaultProps = {
  avatar: undefined,
};

const UserWidget = ({ onLogOut, firstName, lastName, avatar }) => {
  const [open, setOpen] = useState(false);
  const currentElement = useRef(null);

  const handlePanelClosing = e =>
    !currentElement.current.contains(e.target) && setOpen(false);

  useEffect(() => {
    document.addEventListener('mousedown', handlePanelClosing, false);
    return () =>
      document.removeEventListener('mousedown', handlePanelClosing, false);
  }, []);

  const onToggle = () => setOpen(previousValue => !previousValue);

  return (
    <Row className={styles.user_widget_row}>
      <LanguageBar />
      <NotificationBell />
      <div className={styles.user_widget_container} ref={currentElement}>
        <ClickablePanel
          dataCy="userWidget"
          additionalClassNames={styles.username_container}
          onClick={onToggle}
          noAdditionalPadding
        >
          {avatar ? (
            <img src={avatar} alt="avatar" className={styles.avatar} />
          ) : (
            <AvatarPlaceholder
              label={firstName.charAt(0)}
              small
              additionalClassNames={styles.avatar}
            />
          )}
          <span className="flex-align-vertical-center">{`${firstName} ${lastName}`}</span>
          <span
            className={`k-icon k-i-arrow-chevron-${
              open ? 'up' : 'down'
            } flex-align-vertical-center`}
          />
        </ClickablePanel>
        {open && (
          <div className={styles.menu_container}>
            <div className={styles.menu}>
              <ClickablePanel>
                <Link to="/my-profile" onClick={onToggle}>
                  <span
                    className={['k-icon k-i-user', styles.menu_icon].join(' ')}
                  />
                  <span className="option-name">
                    {lang.labels.EditProfile()}
                  </span>
                </Link>
              </ClickablePanel>

              <ClickablePanel onClick={onLogOut} dataCy="logout">
                <div>
                  <span
                    className={['k-icon k-i-logout', styles.menu_icon].join(
                      ' ',
                    )}
                  />
                  <span className="option-name">{lang.labels.LogOut()}</span>
                </div>
              </ClickablePanel>
            </div>
          </div>
        )}
      </div>
    </Row>
  );
};

UserWidget.propTypes = propTypes;
UserWidget.defaultProps = defaultProps;

export default UserWidget;
