import React from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import Logo from 'hh-shared/dist/components/commons/Logo';
import LoginForm from './LoginForm';
import styles from './LoginPage.module.scss';
import LanguageBar from './LanguageBar';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {};

const LoginPage = ({ onSubmit }) => {
  return (
    <div className={styles.login_page_container}>
      <div>
        <Logo />
      </div>
      <div className="fs_large bold bold_medium">
        {lang.labels.LoginPage_Login()}
      </div>
      <div>{lang.labels.LoginPage_LoginDescription()}</div>
      <LoginForm onSubmit={onSubmit} />
      <LanguageBar />
    </div>
  );
};

LoginPage.propTypes = propTypes;
LoginPage.defaultProps = defaultProps;

export default LoginPage;
