"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _reactRouterDom = require("react-router-dom");

var _languageService = _interopRequireDefault(require("../../language/services/languageService"));

var _Page404Module = _interopRequireDefault(require("./Page404.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {};
var defaultProps = {};

function Page404() {
  var boxSideClassName = _Page404Module.default.box_side;
  var frontBoxClassName = (0, _classnames.default)(boxSideClassName, _Page404Module.default.box_side_front);
  var backBoxClassName = (0, _classnames.default)(boxSideClassName, _Page404Module.default.box_side_back);
  var leftBoxClassName = (0, _classnames.default)(boxSideClassName, _Page404Module.default.box_side_left);
  var rightBoxClassName = (0, _classnames.default)(boxSideClassName, _Page404Module.default.box_side_right);
  var topBoxClassName = (0, _classnames.default)(boxSideClassName, _Page404Module.default.box_side_top);
  var topLeftPartBoxClassName = (0, _classnames.default)(boxSideClassName, _Page404Module.default.box_side_top_part, _Page404Module.default['box_side_top_part-left']);
  var topRightPartBoxClassName = (0, _classnames.default)(boxSideClassName, _Page404Module.default.box_side_top_part, _Page404Module.default['box_side_top_part-right']);
  var linkClassName = (0, _classnames.default)('link', _Page404Module.default.content_link);
  return _react.default.createElement("div", {
    className: _Page404Module.default.center
  }, _react.default.createElement("div", {
    className: _Page404Module.default.content
  }, _react.default.createElement("h4", {
    className: _Page404Module.default.content_header
  }, "404"), _react.default.createElement("p", {
    className: _Page404Module.default.content_description
  }, "".concat(_languageService.default.labels.PageNotFound(), "!")), _react.default.createElement("p", {
    className: _Page404Module.default.content_description
  }, "".concat(_languageService.default.labels.TheSiteDoesNotExistOrHasBeenRelocated(), ".")), _react.default.createElement(_reactRouterDom.Link, {
    to: "/",
    className: linkClassName
  }, _languageService.default.labels.ReturnToTheHomePage())), _react.default.createElement("div", {
    className: _Page404Module.default.box_container
  }, _react.default.createElement("div", {
    className: _Page404Module.default.box
  }, _react.default.createElement("div", {
    className: frontBoxClassName
  }, _react.default.createElement("img", {
    src: "./img/logo.png",
    alt: "Logo",
    className: _Page404Module.default.box_logo
  })), _react.default.createElement("div", {
    className: backBoxClassName
  }), _react.default.createElement("div", {
    className: rightBoxClassName
  }), _react.default.createElement("div", {
    className: leftBoxClassName
  }), _react.default.createElement("div", {
    className: topBoxClassName
  }, _react.default.createElement("div", {
    className: topLeftPartBoxClassName
  }), _react.default.createElement("div", {
    className: topRightPartBoxClassName
  })))));
}

Page404.propTypes = propTypes;
Page404.defaultProps = defaultProps;
var _default = Page404;
exports.default = _default;