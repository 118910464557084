import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { isRequiredArray, isNip } from 'fox-react/dist/utils/formValidators';
import Form from 'fox-react/dist/components/Form';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import goBackAfterSuccess from 'fox-react/dist/utils/goBackAfterSuccess';
import FoxInputNumberField from 'fox-react/dist/reduxFormFields/FoxInputNumberField';
import lang from 'hh-shared/dist/language/services/languageService';
import {
  scrollToTheFirstError,
  displayCompletionToast,
} from 'hh-shared/dist/consts/reduxFormMethods';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';
import icons from 'hh-shared/dist/consts/icons';
import SectionWrapper from 'hh-shared/dist/components/layout/SectionWrapper';
import FormSectionCard from 'hh-shared/dist/components/layout/FormSectionCard';
import FoxAutocompleteField from 'fox-react/dist/reduxFormFields/FoxAutocompleteField';
import { zipCodeMask } from 'fox-react/dist/utils/reduxFormUtils';
import { useSelector } from 'react-redux';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const defaultProps = {};

function AddDepartmentInformationForm({ handleSubmit, submitting }) {
  const zipCodeCodes = useSelector(
    state => state.dictionary.static.zipCodeCodes,
  );

  return (
    <Form handleSubmit={handleSubmit}>
      <fieldset>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.DepartmentInformations()}
            titleIcon={icons.department}
          >
            <FoxInputTextField
              label={lang.labels.Name()}
              name={fieldNames.departmentInformationForm.name}
              placeholder={lang.labels.Name()}
              required
            />

            <FoxInputNumberField
              label={lang.labels.AuctionEnumerator()}
              name={fieldNames.departmentInformationForm.auctionEnumerator}
              placeholder={lang.labels.AuctionEnumerator()}
              required
              min={1}
            />

            <FoxInputNumberField
              label={lang.labels.AuctionDraftEnumerator()}
              name={fieldNames.departmentInformationForm.auctionDraftEnumerator}
              placeholder={lang.labels.AuctionDraftEnumerator()}
              required
              min={1}
            />

            <FoxInputNumberField
              label={lang.labels.TransportOrderEnumerator()}
              name={
                fieldNames.departmentInformationForm.transportOrderEnumerator
              }
              placeholder={lang.labels.TransportOrderEnumerator()}
              required
              min={1}
            />
          </FormSectionCard>
        </SectionWrapper>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.DataForTransportOrder()}
            titleIcon={icons.editedDocument}
          >
            <FoxInputTextField
              label={lang.labels.FullName()}
              name={fieldNames.departmentInformationForm.fullName}
              placeholder={lang.labels.FullName()}
              required
            />

            <FoxInputTextField
              label={lang.labels.Nip()}
              placeholder={lang.labels.Nip()}
              name={fieldNames.departmentInformationForm.nip}
              required
            />

            <FoxAutocompleteField
              label={lang.labels.ZipCode()}
              placeholder={lang.labels.ZipCode()}
              name={fieldNames.departmentInformationForm.zipCode}
              required
              items={zipCodeCodes}
              {...zipCodeMask}
            />

            <FoxInputTextField
              label={lang.labels.City()}
              placeholder={lang.labels.City()}
              name={fieldNames.departmentInformationForm.city}
              required
            />

            <FoxInputTextField
              label={lang.labels.Street()}
              placeholder={lang.labels.Street()}
              name={fieldNames.departmentInformationForm.street}
              required
            />
          </FormSectionCard>
        </SectionWrapper>
        <SectionWrapper>
          <FormSectionCard
            title={lang.labels.DataForInvoiceTransportOrder()}
            titleIcon={icons.documentWithData}
          >
            <FoxInputTextField
              label={lang.labels.FullName()}
              name={fieldNames.departmentInformationForm.invoiceDestinationName}
              placeholder={lang.labels.FullName()}
              required
            />

            <FoxAutocompleteField
              label={lang.labels.ZipCode()}
              placeholder={lang.labels.ZipCode()}
              name={
                fieldNames.departmentInformationForm.invoiceDestinationZipCode
              }
              required
              items={zipCodeCodes}
              {...zipCodeMask}
            />

            <FoxInputTextField
              label={lang.labels.City()}
              placeholder={lang.labels.City()}
              name={fieldNames.departmentInformationForm.invoiceDestinationCity}
              required
            />

            <FoxInputTextField
              label={lang.labels.Street()}
              placeholder={lang.labels.Street()}
              name={
                fieldNames.departmentInformationForm.invoiceDestinationStreet
              }
              required
            />

            <FoxInputTextField
              label={lang.labels.InvoiceEmailAddress()}
              placeholder={lang.labels.InvoiceEmailAddress()}
              name={fieldNames.departmentInformationForm.emailInvoiceAddress}
              required
            />
          </FormSectionCard>
        </SectionWrapper>
      </fieldset>
      <FoxButton primary disabled={submitting}>
        {lang.buttons.Save()}
      </FoxButton>
    </Form>
  );
}

AddDepartmentInformationForm.propTypes = propTypes;
AddDepartmentInformationForm.defaultProps = defaultProps;

const validate = values => {
  const errors = {};

  isRequiredArray(
    errors,
    values,
    [
      fieldNames.departmentInformationForm.name,
      fieldNames.departmentInformationForm.auctionEnumerator,
      fieldNames.departmentInformationForm.auctionDraftEnumerator,
      fieldNames.departmentInformationForm.transportOrderEnumerator,
      fieldNames.departmentInformationForm.fullName,
      fieldNames.departmentInformationForm.zipCode,
      fieldNames.departmentInformationForm.city,
      fieldNames.departmentInformationForm.street,
      fieldNames.departmentInformationForm.invoiceDestinationName,
      fieldNames.departmentInformationForm.invoiceDestinationZipCode,
      fieldNames.departmentInformationForm.invoiceDestinationCity,
      fieldNames.departmentInformationForm.invoiceDestinationStreet,
      fieldNames.departmentInformationForm.emailInvoiceAddress,
      fieldNames.departmentInformationForm.nip,
    ],
    lang.validationMessages.FieldRequired(),
  );

  isNip(
    errors,
    values,
    fieldNames.departmentInformationForm.nip,
    lang.validationMessages.InvalidNip(),
  );

  return errors;
};

const onSubmitSuccess = (result, dispatch) =>
  displayCompletionToast(dispatch, lang.labels.SuccessfullyCreatedDepartment());

export default reduxForm({
  form: formNames.departmentInformationForm,
  validate,
  enableReinitialize: true,
  onSubmitFail: scrollToTheFirstError,
  onSubmitSuccess,
})(goBackAfterSuccess(AddDepartmentInformationForm));
