"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // File was autogenerated by tools/generateLabels.js script

var _default = {
  NotSupportedNotificationId: "Unresponsive type of notification",
  SaveSuccessful: "Saved successfully",
  SelectDate: "Select the date",
  FieldRequired: "Required field",
  Welcome: "Hello",
  BoxPicture: "Picture of the box",
  Currency: "Currency",
  Expense: "Cost",
  LoadingData: "Loading data",
  Transport: "Transport",
  UnloadingData: "Unloading data",
  CompanyNotFoundToEdit: "No company was found to update",
  AccountNotFoundProbablyTokenHasExpiredOrIncorrectEmail: "An account has not been found, probably your token has expired or the e-mail address is incorrect",
  CurrentPasswordIsNotCorrect: "The current password is not correct",
  DepartmentWithThisNameAlreadyExists: "There is already a branch with such a name",
  EmailAddressNotFound: "No such e-mail address was found",
  EmailHasAlreadyBeenSent: "E-mail has already been sent",
  EmailNotFound: "No email found",
  FieldMustBeValidEmail: "The field must be the correct e-mail address",
  MissingIdOrInstanceId: "There is a lack of identifier, contact the Administrator",
  HtmlTemplateNotFound: "HTML template was not found",
  IncorrectLoginDetails: "Incorrect login details",
  NewPasswordWasSet: "The new password has been set",
  NotValidPassword: "The password should contain a lower, lower letter, digit and special sign",
  PasswordChanged: "The password has been changed",
  PasswordsNotEquals: "The introduced passwords do not fit together",
  ResetPasswordSend: "The password reset instructions have been sent to the e-mail address provided",
  SelectedLoginIsAlreadyTakenSelectAnother: "The selected login is already busy select another",
  ThereIsNoSuchCarType: "This type of vehicle is not",
  ThereIsNoSuchDepartment: "There is no such branch",
  ThereIsNoSuchHtmlTemplate: "There is no such html template",
  ThereIsNoSuchUser: "There is no such user",
  UserInformationUpdated: "User data has been updated",
  UserWithThisEmailAlreadyExists: "There is already a user with such an e-mail address",
  UserWithThisLoginAlreadyExists: "There is already a user with such a login",
  InvalidNip: "The given value is not a correct NIP.",
  CarNotFoundToEdit: "No vehicle was found for update",
  FacilityNotFoundToEdit: "No factory was found for update",
  RouteNotFoundToEdit: "No route was found to update",
  ThereIsNoSuchCurrency: "There is no such currency",
  ThereIsNoSuchTransportOrder: "There is no such order",
  ThereIsNoSuchAuction: "There is no such auction",
  InvalidRegon: "The given value is not a correct REGON.",
  ProblemWithGus: "There was a problem with the connection with the CSO",
  GusDataLoaded: "Data from the Central Statistical Office was loaded",
  YourAccountCannotLogin: "Your account cannot log in",
  DepartmentRequiredForDepAdmin: "The branch is required for the role of the Department Administrator",
  FacilityRequiredForEmployee: "The plant is required for the role of the employee",
  AddressFromCannotBeEqualToAddressTo: "The address from it cannot be the same as the address to",
  NotFoundRoute: "No such route was found",
  RouteAlreadyExists: "The route with the given addresses already exists",
  FileExtensionIsNotSupported: "The file extension is not supported",
  FileIsEmpty: "The file is empty",
  CompanyNotFoundWithThisNipNumber: "No company was found with such a NIP number",
  LoadingUnloadingAddressesCannotBeTheSame: "Loading and unloading address cannot be the same",
  TimeFromCannotBeLaterThanTimeTo: "Time from it cannot be later than time to",
  LoadingTimeCannotBeLaterThanUnloadingTime: "Loading time cannot be later than the unloading time",
  CompanyWithThatNipAlreadyExists: "Another company with a given nipp",
  ThereIsNoSuchDefaultPrice: "There is no such default price",
  YourSessionHasExpired: "Your session has expired",
  TransportOrderAlreadyAccepted: "The order has already been accepted",
  ZipCodeAlreadyExist: "Such a postal code already exists",
  ThereIsNoSuchZipCode: "There is no such postal code",
  ZipCodeFromCannotBeEqualToZipCodeTo: "Postal code from",
  PriceCannotBeLessThan0: "The price cannot be less than 0",
  PriceShouldBeLessThanLastBid: "The price must be smaller than the last offer",
  ThereIsNoSuchDriver: "There is no driver with a given identifier",
  ThereIsNoSuchCar: "There is no car with the identifier given",
  ThereIsNoSuchFacility: "There is no such plant",
  CannotEditStartedAuction: "You can't edit the auction that has already started",
  AuctionAlreadyPublished: "Auction cannot be published other than in the draft status",
  CannotDeleteAuctionDifferentThanDraftOrForPublication: "Auction cannot be removed because it is in a different status than Draft and for publication.",
  CannotSaveZipCodeAlreadyExist: "The defined postal code already exists",
  AuctionIsNotLiveOrNotExists: "The auction does not exist or is not active.",
  CompanyDidNotAllowToBidOnItsBehalf: "The company did not agree to auction on her behalf.",
  TransportOrderAlreadyCanceled: "The order has already been canceled",
  TransportOrderCanceled: "The order has been canceled",
  YouCanOnlyAcceptPlacedTransportOrderOrUpdate: "You can only accept the order which is in the status complex or update accepted.",
  YouCanOnlyLoadedAcceptedTransportOrder: "You can only upload the order which is in the status accepted for approval.",
  YouCanOnlyRealizeLoadedTransportOrder: "You can only complete an order that is loaded in the status.",
  CannotBePastDate: "The date cannot be the past date",
  YouCannotCancelNotLiveAuction: "Auction that is not ongoing cannot be canceled.",
  SuccessUpdatedCommentOnTransportOrder: "The comment has been updated.",
  YouMustAcceptRodo: "You must accept the principles of the GDPR",
  YouMustCheckReadConditions: "You need to get acquainted with the conditions of loading, unloading and storage of H+H Polska mobile concrete products",
  YouMustCheckReadPrinciples: "You need to familiarize yourself with the rules of safe loading of products to recipient cars",
  CannotUpdateNonPlacedTransportOrder: "An order that is in status other than issued cannot be acted",
  CannotUpdateRealizedTransportOrder: "The completed order cannot be acted",
  ThereIsNoSuchCompanyOrCompanyIsInactive: "There is no such company or the company is inactive",
  NoFile: "No file has been sent",
  FieldValueIncorrect: "The field has a not correct value",
  UnhandledErrorOccured: "He made an error about the ID:",
  ImportedWithoutInvalidNip: "IMPORTANT, with the exception of non -correct NIPS"
};
exports.default = _default;