"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // File was autogenerated by tools/generateLabels.js script

var _default = {
  CompanyNotFoundToEdit: "Es wurde kein Unternehmen gefunden, um aktualisiert zu werden",
  AccountNotFoundProbablyTokenHasExpiredOrIncorrectEmail: "Es wurde kein Konto gefunden, wahrscheinlich ist Ihr Token abgelaufen oder die E-Mail-Adresse ist falsch",
  CurrentPasswordIsNotCorrect: "Das aktuelle Passwort ist nicht korrekt",
  DepartmentWithThisNameAlreadyExists: "Es gibt bereits einen Zweig mit einem solchen Namen",
  EmailAddressNotFound: "Es wurde keine solche E-Mail-Adresse gefunden",
  EmailHasAlreadyBeenSent: "E-Mail wurde bereits gesendet",
  EmailNotFound: "Keine E -Mail gefunden",
  FieldMustBeValidEmail: "Das Feld muss die richtige E-Mail-Adresse sein",
  FieldRequired: "Pflichtfeld",
  MissingIdOrInstanceId: "Es gibt einen Mangel an Kennung, wenden Sie sich an den Administrator",
  HtmlTemplateNotFound: "Die HTML -Vorlage wurde nicht gefunden",
  IncorrectLoginDetails: "Falsche Anmeldeinformationen",
  NewPasswordWasSet: "Das neue Passwort wurde festgelegt",
  NotValidPassword: "Das Passwort sollte einen niedrigeren, niedrigeren Buchstaben, Ziffern und Sonderzeichen enthalten",
  PasswordChanged: "das Passwort wurde geändert",
  PasswordsNotEquals: "Die eingeführten Passwörter passen nicht zusammen",
  ResetPasswordSend: "Die Anweisungen zum Zurücksetzen des Passworts wurden an die angegebene E-Mail-Adresse gesendet",
  SaveSuccessful: "Erfolgreich gespeichert",
  SelectDate: "Wählen Sie das Datum",
  SelectedLoginIsAlreadyTakenSelectAnother: "Die ausgewählte Anmeldung ist bereits beschäftigt, wählen Sie eine andere Auswahl",
  ThereIsNoSuchCarType: "Diese Art von Fahrzeug ist nicht",
  ThereIsNoSuchDepartment: "Es gibt keinen solchen Zweig",
  ThereIsNoSuchHtmlTemplate: "Es gibt keine solche HTML -Vorlage",
  ThereIsNoSuchUser: "Es gibt keinen solchen Benutzer",
  UserInformationUpdated: "Benutzerdaten wurden aktualisiert",
  UserWithThisEmailAlreadyExists: "Es gibt bereits einen Benutzer mit einer solchen E-Mail-Adresse",
  UserWithThisLoginAlreadyExists: "Es gibt bereits einen Benutzer mit einer solchen Anmeldung",
  Welcome: "Hallo",
  InvalidNip: "Der angegebene Wert ist kein korrekter Nip.",
  CarNotFoundToEdit: "Für das Update wurde kein Fahrzeug gefunden",
  FacilityNotFoundToEdit: "Für das Update wurde keine Fabrik gefunden",
  RouteNotFoundToEdit: "Es wurde keine Route gefunden, um aktualisiert zu werden",
  ThereIsNoSuchCurrency: "Es gibt keine solche Währung",
  ThereIsNoSuchTransportOrder: "Es gibt keine solche Reihenfolge",
  ThereIsNoSuchAuction: "Es gibt keine soziale Auktion",
  InvalidRegon: "Der angegebene Wert ist kein korrektes Regon.",
  ProblemWithGus: "Es gab ein Problem mit der Verbindung mit dem CSO",
  GusDataLoaded: "Daten aus dem zentralen statistischen Büro wurden geladen",
  YourAccountCannotLogin: "Ihr Konto kann sich nicht anmelden",
  DepartmentRequiredForDepAdmin: "Die Filiale ist für die Rolle des Abteilungsadministrators erforderlich",
  FacilityRequiredForEmployee: "Die Anlage ist für die Rolle des Mitarbeiter erforderlich",
  AddressFromCannotBeEqualToAddressTo: "Die Adresse daraus kann nicht die gleiche sein wie die Adresse an",
  NotFoundRoute: "Es wurde keine solche Route gefunden",
  RouteAlreadyExists: "Die Route mit den angegebenen Adressen gibt es bereits",
  FileExtensionIsNotSupported: "Die Dateierweiterung wird nicht unterstützt",
  FileIsEmpty: "Die Datei ist leer",
  CompanyNotFoundWithThisNipNumber: "Es wurde keine Firma mit einer solchen Nip -Nummer gefunden",
  LoadingUnloadingAddressesCannotBeTheSame: "Das Laden und Entladen von Adresse kann nicht gleich sein",
  TimeFromCannotBeLaterThanTimeTo: "Die Zeit daraus kann nicht später als Zeit sein",
  LoadingTimeCannotBeLaterThanUnloadingTime: "Die Ladezeit kann nicht später als die Entladungszeit sein",
  CompanyWithThatNipAlreadyExists: "Eine andere Firma mit einem bestimmten NIPP",
  ThereIsNoSuchDefaultPrice: "Es gibt keinen solchen Standardpreis",
  BoxPicture: "Bild der Box",
  Currency: "Währung",
  Expense: "Kosten",
  LoadingData: "Daten werden geladen",
  Transport: "Transport",
  UnloadingData: "Daten entladen",
  NotSupportedNotificationId: "Nicht reagierende Art der Benachrichtigung",
  YourSessionHasExpired: "Deine Sitzung ist abgelaufen",
  TransportOrderAlreadyAccepted: "Die Bestellung wurde bereits angenommen",
  ZipCodeAlreadyExist: "Eine solche Postleitzahl existiert bereits",
  ThereIsNoSuchZipCode: "Es gibt keine solche Postleitzahl",
  ZipCodeFromCannotBeEqualToZipCodeTo: "Postleitzahl von",
  PriceCannotBeLessThan0: "Der Preis kann nicht weniger als 0 betragen",
  PriceShouldBeLessThanLastBid: "Der Preis muss kleiner sein als das letzte Angebot",
  ThereIsNoSuchDriver: "Es gibt keinen Fahrer mit einem bestimmten Kennung",
  ThereIsNoSuchCar: "Es gibt kein Auto mit der angegebenen Kennung",
  ThereIsNoSuchFacility: "Es gibt keine solche Pflanze",
  CannotEditStartedAuction: "Sie können die Auktion, die bereits begonnen hat, nicht bearbeiten",
  AuctionAlreadyPublished: "Auktion kann nur im Entwurfsstatus veröffentlicht werden",
  CannotDeleteAuctionDifferentThanDraftOrForPublication: "Auktion kann nicht entfernt werden, da sie sich in einem anderen Status als Entwurf und zur Veröffentlichung befindet.",
  CannotSaveZipCodeAlreadyExist: "Die definierte Postleitzahl existiert bereits",
  AuctionIsNotLiveOrNotExists: "Die Auktion existiert nicht oder ist nicht aktiv.",
  CompanyDidNotAllowToBidOnItsBehalf: "Das Unternehmen stimmte der Auktion in ihrem Namen nicht zu.",
  TransportOrderAlreadyCanceled: "Die Bestellung wurde bereits storniert",
  TransportOrderCanceled: "Die Bestellung wurde storniert",
  YouCanOnlyAcceptPlacedTransportOrderOrUpdate: "Sie können nur die Bestellung akzeptieren, die sich im Statuskomplex befindet oder akzeptiert wird.",
  YouCanOnlyLoadedAcceptedTransportOrder: "Sie können die Bestellung nur hochladen, die im Status zur Genehmigung angenommen wird.",
  YouCanOnlyRealizeLoadedTransportOrder: "Sie können nur eine Bestellung ausfüllen, die im Status geladen wird.",
  CannotBePastDate: "Das Datum kann nicht das vergangene Datum sein",
  YouCannotCancelNotLiveAuction: "Die nicht laufende Auktion kann nicht abgesagt werden.",
  SuccessUpdatedCommentOnTransportOrder: "Der Kommentar wurde aktualisiert.",
  YouMustAcceptRodo: "Sie müssen die Grundsätze der DSGVO akzeptieren",
  YouMustCheckReadConditions: "Sie müssen sich mit den Bedingungen für das Laden, Entladen und Speichern von H+H -Polska -Betonprodukten kennenlernen",
  YouMustCheckReadPrinciples: "Sie müssen sich mit den Regeln für die sichere Beladung von Produkten in Empfängerautos vertraut machen",
  CannotUpdateNonPlacedTransportOrder: "Eine andere Bestellung, die sich nicht ausgestellt hat, kann nicht gehandelt werden",
  CannotUpdateRealizedTransportOrder: "Die abgeschlossene Bestellung kann nicht gehandelt werden",
  ThereIsNoSuchCompanyOrCompanyIsInactive: "Es gibt kein solches Unternehmen oder das Unternehmen ist inaktiv",
  NoFile: "Es wurde keine Datei gesendet",
  FieldValueIncorrect: "Das Feld hat einen nicht korrekten Wert",
  UnhandledErrorOccured: "Er machte einen Fehler über die ID:",
  ImportedWithoutInvalidNip: "Wichtig, mit Ausnahme von nicht korrekten NIPs"
};
exports.default = _default;