"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // File was autogenerated by tools/generateLabels.js script

var _default = {
  Edit: "Edition",
  EditProfile: "Profile editing",
  EmailOrLogin: "E-mail or login",
  LoginPage_ForgotPassword: "I forgot my password",
  LoginPage_Login: "Log in",
  LoginPage_LoginDescription: "After authorization, you will get access to the management panel",
  LogOut: "log out",
  Password: "Password",
  Welcome: "Hello",
  Email: "E-mail",
  ResetPasswordInformation: "A password link will be sent to the e-mail address provided",
  NewPassword: "A new password",
  RepeatPassword: "Repeat password",
  BackToMainPage: "Return to the main page",
  LoginPage: "login page",
  UsersManagement_Columns_Email: "E-mail",
  UsersManagement_Columns_Login: "Login",
  YourPasswordWasChanged: "your password has been changed",
  HtmlTemplatesManagement_Columns_Description: "Description",
  HtmlTemplatesManagement_Columns_Name: "Name",
  AddingNewUser: "Adding a new user",
  UserInformations: "User data",
  Login: "Login",
  Roles: "Role",
  EditingUser: "User edition",
  UserManagement_Columns_Roles: "Roles",
  AddingNewDepartment: "Adding a new branch",
  DepartmentInformations: "Department of the branch",
  Name: "Name",
  UserManagement_Columns_Name: "Name",
  EditingDepartment: "Edition of the Branch",
  Content: "Contents",
  Description: "Description",
  EditingHtmlTemplates: "HTML edition",
  HtmlTemplateInformations: "HTML template data",
  FirstName: "Name",
  LastName: "Last name",
  UsersManagement_Columns_FirstName: "Name",
  UsersManagement_Columns_LastName: "Last name",
  CurrentPassword: "Current password",
  UserChangePassword: "Password change",
  Companies: "Companies",
  AddingNewCompany: "Adding a new company",
  Address: "Address",
  City: "City",
  CompanyInformations: "Company's data",
  EditingCompany: "Company edition",
  Nip: "NIP",
  PhoneNumber: "Phone number",
  ZipCode: "Zip code",
  Version: "Version",
  AvailablePlaceholders: "PlaceHolders available",
  AddingNewCar: "Adding a new vehicle",
  AddingNewCarType: "Adding a new type of vehicle",
  CarInformations: "Vehicle data",
  CarTypeInformations: "Vehicle type data",
  EditingCar: "Vehicle editing",
  EditingCarType: "Vehicle type editing",
  Cars: "Vehicles",
  CarType: "Vehicle type",
  CarTypes: "Vehicle types",
  CarTypesManagement_Columns_Name: "Name",
  Comment: "Comment",
  Company: "Business",
  RegisterNumber: "Registration number",
  AddingNewFacility: "Adding a new plant",
  Department: "Branch",
  EditingFacilities: "Editing the plant",
  Facilities: "Betting",
  FacilityInformations: "Department of the plant",
  AdditionalUserInformations: "Additional user data",
  AddingNewRoute: "Adding a new route",
  CityFrom: "Initial city",
  CityTo: "Target city",
  Distance: "Distance",
  EditingRoute: "Edition of the route",
  RouteInformations: "Route data",
  Routes: "Routes",
  ZipCodeFrom: "Initial postal code",
  ZipCodeTo: "Target postal code",
  Departments: "Departments",
  HtmlTemplates: "HTML templates",
  Users: "Users",
  Avatar: "Avatar",
  AddingNewAuction: "Adding a new auction",
  AuctionInformation: "Auction data",
  AuctionNumber: "Auction number",
  Auctions: "Auctions",
  AdditionalRequirements: "Additional requirements",
  AdditionalInformations: "Additional information",
  NoAdditionalInformations: "No additional information",
  AuctionType: "Auction type",
  AuthorizedPickupPerson: "Company/person authorized to pick up",
  AuthorizedPickupPersonInformations: "Data of the person authorized to be picked up",
  Capacity: "Capacity",
  EndDateTime: "Date and time",
  Facility: "Bet",
  LoadingDateTime: "Date and time of loading",
  LoadingMethod: "Loading method",
  LoadingData: "Loading data",
  PricePerKilometer: "Price per kilometer",
  StartDateTime: "Date and start time",
  StartPrice: "starting price",
  StartingPrice: "starting price",
  UnloadingData: "Unloading data",
  UnloadingDateTime: "Date and time of unloading",
  UnloadingMethod: "Unloading",
  AddingNewCurrency: "Adding a new currency",
  Currencies: "Currency",
  CurrenciesManagement_Columns_IsDefault: "Or default",
  CurrenciesManagement_Columns_Name: "Name",
  CurrencyInformations: "Currency data",
  EditingCurrency: "Currency edition",
  IsDefault: "Or default",
  PrincipalPerson: "Principal",
  Route: "Route",
  Subjects: "Entities",
  AddingNewTransportOrder: "Adding a new order",
  Car: "Vehicle",
  Driver: "Driver",
  EditingTransportOrder: "Order editing",
  RealizationDate: "Date of implementation",
  TransportOrders: "Orders",
  TransportOrdersManagement_Columns_AuctionNumber: "Auction number",
  TransportOrdersManagement_Columns_Company: "Business",
  TransportOrdersManagement_Columns_Driver: "Driver",
  TransportOrdersManagement_Columns_Distance: "Distance",
  TransportOrdersManagement_Columns_LoadingTime: "Date and time of loading",
  TransportOrdersManagement_Columns_Number: "Number",
  TransportOrdersManagement_Columns_Value: "Value",
  TransportOrdersManagement_Columns_Capacity: "Capacity",
  TransportOrdersManagement_Columns_UnloadingMethod: "Unloading",
  TransportOrdersManagement_Columns_LoadingMethod: "Loading method",
  TransportOrdersManagement_Columns_Status: "Status",
  TransportOrdersManagement_Columns_UnloadingAddress: "Unloading address",
  TransportOrdersManagement_Columns_AuthorizedPickupPerson: "A person authorized to pick up",
  TransportOrderInformations: "Order data",
  IsDriver: "Or driver",
  UsersManagement_Columns_Company: "Business",
  UsersManagement_Columns_Facility: "Bet",
  UsersManagement_Columns_IsDriver: "Or driver",
  UsersManagement_Columns_PhoneNumber: "Phone number",
  TransportOrdersManagement_Columns_Car: "Vehicle",
  TransportOrdersManagement_Columns_LoadingAddress: "Loading address",
  TransportOrdersManagement_Columns_Facility: "Bet",
  TransportOrdersManagement_Columns_UnloadingTime: "Date and time of unloading",
  LoadingAddress: "Loading address",
  UnloadingAddress: "Unloading address",
  CurrentPrice: "Current price",
  Observed: "Observed",
  TimeLeft: "To end",
  UnloadingTime: "Date and time of unloading",
  AuctionEndTime: "End of the auction",
  ContactPerson: "Contact person",
  NewAddress: "New address",
  Street: "Street",
  AddressFrom: "Address from",
  AddressTo: "Address",
  LoadData: "Loading date",
  NewCar: "New vehicle",
  NewDriver: "New driver",
  Value: "Value",
  TransportOrdersManagement_Columns_AdditionalRequirements: "Additional requirements",
  Regon: "Rating",
  EditingAuction: "Auction edition",
  AuctionDraftEnumerator: "Draft auction number",
  AuctionEnumerator: "Auction number",
  DepAdmin: "Department Administrator",
  Employee: "Employee",
  Logistician: "Logistics",
  MainAdmin: "Main administrator",
  UserPermissions: "User permissions",
  EditAuctionDraftEnumerator: "Edit draft auction enumerator",
  EditAuctionEnumerator: "Edit auction enumerator",
  AddingNewAddress: "Adding a new address",
  Addresses: "Addresses",
  AddressInformations: "Address data",
  Prices: "Prices",
  PricesManagement_Columns_BuyerRebateGroup: "Group Rabat. buyers",
  PricesManagement_Columns_Code: "Code",
  PricesManagement_Columns_Company: "Business",
  PricesManagement_Columns_CurrencyCode: "Currency code",
  PricesManagement_Columns_Description: "Description",
  PricesManagement_Columns_FlatRate: "Flat rate",
  PricesManagement_Columns_LocalizationCode: "Location code",
  PricesManagement_Columns_MaxBasis: "Max. Basis",
  PricesManagement_Columns_MaxDistance: "Max. Distance",
  PricesManagement_Columns_MinBasis: "Min. Basis",
  PricesManagement_Columns_MinDistance: "Min. Distance",
  PricesManagement_Columns_Nip: "NIP",
  PricesManagement_Columns_No: "Yeah.",
  PricesManagement_Columns_RatePerBaseUnit: "Rate per. Base Unit",
  PricesManagement_Columns_ShipmentMethod: "Shipment Method",
  PricesManagement_Columns_ShippingAgentCode: "Shipping Agent Code",
  PricesManagement_Columns_ShippingAgentServiceCode: "Shipping Agent Service Code",
  PricesManagement_Columns_Type: "Type",
  PricesManagement_Columns_ZoneNo: "Zone no.",
  PricesManagement: "Price management",
  YourOffer: "your offer",
  Bid: "Bid",
  NoOffer: "No offer",
  You: "You",
  OrdinalNumber: "LP.",
  HistoricPrice: "Historical price",
  Offerts: "Offers",
  Time: "Time",
  Suppliers: "Suppliers",
  InvitedSuppliers: "Invited suppliers",
  Loading: "Loading",
  Supply: "Delivery",
  PriceForKilemeter: "Price per km",
  Difference: "Difference",
  AuctionHasNotBeenStarted: "The auction has not been started!",
  AuctionHasEnded: "The auction has been completed!",
  Auction: "Auction",
  AuctionTimeDuration: "Duration of the auction",
  UserBanned: "Banned",
  Templates: "Templates",
  Dictionaries: "Dictionaries",
  AddingNewDefaultPrice: "Adding a new default price",
  DefaultPriceInformations: "Data on the default price",
  DefaultPrices: "Default prices",
  DefaultPricesManagement_Columns_FlatRate: "Rate",
  DefaultPricesManagement_Columns_MaxDistance: "Maximum distance",
  DefaultPricesManagement_Columns_MinDistance: "Minimum distance",
  EditingDefaultPrice: "Editing the default price",
  FlatRate: "Rate",
  MaxDistance: "Maximum distance",
  MinDistance: "Minimum distance",
  Drivers: "Drivers",
  PriceLists: "Price lists",
  Blocked: "Blocked",
  BlockingCompany: "Blocking the company",
  Orders: "Orders",
  OrdersDashboard: "Orders",
  BoxPicture: "Picture of the box",
  Currency: "Currency",
  Expense: "Cost",
  Transport: "Transport",
  TimeToLoad: "Time to load",
  TransportOrder: "Transport order",
  TransportOrderNavNumber: "NAV order number",
  MandatoryCompany: "Mandatory",
  ChangeStatus: "Status change",
  Delivery: "Delivery",
  DeliveryExpense: "Cost of delivery",
  PaymentDeadline: "Date of payment",
  EditingAddress: "Address editing",
  Yes: "Yes",
  No: "NO",
  DriversQuantity: "Number of drivers",
  OrderNavNumber: "NAV order number",
  ZipCodes: "Postal Codes",
  MainPage: "Home",
  SmsNotification: "SMS notification",
  EmailNotification: "E-mail notification",
  Day: "Day",
  Days: "Days",
  AddingZipCode: "Adding a new postal code",
  EditingZipCode: "Edition of the postal code",
  NewZipCode: "New zip code",
  Exposed: "Exposed",
  AcceptedForRealization: "Abroad",
  Loaded: "Loaded",
  Completed: "Realized",
  TimeToUnload: "Time to unload",
  AddToFollowed: "Add to followed",
  ButtonIsDisabled: "The button is turned off",
  AcceptedTransportOrdersNumber: "Number of accepted orders",
  OrderedTransportOrdersNumber: "Number of orders ordered",
  ParticipatedAuctionsNumber: "The number of auctions in which participation was taken",
  WinAuctionsNumber: "Number of auctions won",
  EditTransportOrderEnumerator: "Edit order enumerator",
  TransportOrderEnumerator: "Order numberer",
  ToTheEndOfTheAuctionRemained: "It remained until the end of the auction",
  AuctionWillTakePlaceIn: "The auction will start with",
  New: "New",
  AuthorizedPickupPersonPhoneNumber: "Phone number of the person/company authorized to pick up",
  AuthorizedPickupPersonPhoneNumberPlaceholder: "to the person authorized to pick up",
  TransportOrdersManagement_Columns_AuthorizedPickupPersonPhoneNumber: "Contact phone to a person authorized to pick up",
  DifferentCompany: "Another company",
  Expand: "Expand",
  Fold: "Collapse",
  ElectricInvoice: "For electronic invoices",
  InvoiceSendToaddress: "Please send the invoice to the address",
  YouWin: "You win",
  DownloadPdf: "Download pdf",
  ShowPdf: "Show pdf",
  Order: "Order",
  AddingNewDriver: "Adding a new driver",
  EditingDriver: "Driver edition",
  Settings: "Settings",
  Ongoing: "Ongoing",
  DashboardOngoing: "Ongoing",
  DashboardFinishedWithSuccess: "Successfully completed",
  DashboardFinishedWithoutSuccess: "Ended without success",
  WithMyParticipation: "We bid",
  Canceled: "Canceled",
  Finished: "Ended",
  Won: "Wins",
  Lost: "Loser",
  NotWon: "Not winning",
  All: "All",
  Accepted: "Accepted",
  OrderSuffix: "Suffix order number",
  DataForInvoiceTransportOrder: "Data to send an invoice",
  DataForTransportOrder: "Data for the order",
  FullName: "Full name",
  InvoiceEmailAddress: "E-mail address for sending invoice",
  Date: "Date",
  GoToTheEvent: "Go to the event",
  Draft: "Draft",
  Ended: "Finished",
  SuccessfullyCreatedAuction: "A new auction was successfully created",
  SuccessfullyModificatedAuction: "Auction was successfully modified",
  SuccessfullyCreatedOrder: "A new order was successfully created",
  SuccessfullyModificatedOrder: "The order was successfully modified",
  SuccessfullyCreatedUser: "A new user was successfully created",
  SuccessfullyModificatedUser: "The user was successfully modified",
  SuccessfullyCreatedDepartment: "A new branch was successfully created",
  SuccessfullyModificatedDeparment: "The branch was successfully modified",
  SuccessfullyCreatedFacility: "A new plant was successfully created",
  SuccessfullyModificatedFacility: "The plant was successfully modified",
  SuccessfullyCreatedCompany: "A new company was successfully created",
  SuccessfullyModificatedCompany: "The company was successfully modified",
  SuccessfullyCreatedAddress: "A new address was successfully created",
  SuccessfullyModificatedAddress: "The address was successfully modified",
  SuccessfullyCreatedRoute: "A new route was successfully created",
  SuccessfullyModificatedRoute: "The route was successfully modified",
  SuccessfullyCreatedVehicle: "A new vehicle was successfully created",
  SuccessfullyModificatedVehicle: "The vehicle was successfully modified",
  SuccessfullyCreatedZipCode: "A new postal code was successfully created",
  SuccessfullyModificatedZipCode: "Postal code was successfully modified",
  SuccessfullyCreatedDefaultPrice: "A new default price was successfully created",
  SuccessfullyModificatedDefaultPrice: "The default price was successfully modified",
  SuccessfullyModificatedHTMLTemplate: "HTML template has been successfully modified",
  SuccessfullyCreatedCarType: "A new type of vehicle was successfully created",
  SuccessfullyModificatedCarType: "The vehicle type was successfully modified",
  SuccessfullyCreatedCurrency: "A new currency has been successfully added",
  SuccessfullyModificatedCurrency: "The currency was successfully modified",
  SuccessfullyCreatedDriver: "A new driver was successfully added",
  SuccessfullyModificatedDriver: "The driver was successfully modified",
  CanceledTransportOrderStatus: "Canceled",
  AcceptedTransportOrderStatus: "Abroad",
  DashboardPlacedTransportOrderStatus: "Issued",
  DashboardAcceptedTransportOrderStatus: "Abroad",
  CompletedTransportOrderStatus: "Realized",
  DashboardCompletedTransportOrderStatus: "Realized",
  ConfigurableDiagramCompletedTransportOrders: "Was realized",
  LoadedTransportOrderStatus: "Loaded",
  DashboardLoadedTransportOrderStatus: "Loaded",
  CanceledAuctionStatus: "Canceled",
  LiveAuctionStatus: "Ongoing",
  Status: "Status",
  IsParticipant: "We bid",
  IsWon: "I win",
  WithSuccess: "Successfully",
  WithoutSuccess: "Without success",
  WithoutOffer: "No offer",
  EmailSubject: "Email topic",
  HomePage: "Home",
  Preview: "Preview",
  BidingByHHAllowed: "Permission to bid by an HH employee",
  NotGiven: "Not given",
  LackOfInformations: "Lack of information",
  LackOfData: "No data",
  EndedAuctionStatus: "Finished",
  Placed: "Exposed",
  PlacedTransportOrderStatus: "Exposed",
  YouAreWinning: "You win",
  YouAreLosing: "You lose",
  AddingNewTransportOrderBasedOnAuction: "Adding an order based on auction",
  ClosedAuctionStatus: "Finished",
  InJanuary: "In January",
  InFebruary: "In February",
  InMarch: "In March",
  InApril: "In April",
  InMay: "In May",
  InJune: "In June",
  InJuly: "In July",
  InAugust: "In August",
  InSeptember: "In September",
  InOctober: "In October",
  InNovember: "In November",
  InDecember: "In December",
  ForPublication: "For publication",
  Dashboard: "Main screen",
  AuctionHasBeen: "The auction stayed",
  CurrentAuctionStatus: "Current status of auction",
  InsideYourClipboardIsLocatedPlaceholder: "There is a placeholder in your storage",
  PasteItIntoAppropriatePlaceInsideTheTemplate: "Paste it into the right place on the template",
  AuctionPage_AuctionCancelation: "Auction cancellation",
  AuctionPage_CancelAuction: "Cancel the auction",
  AuctionPage_CancelAuctionDialogDescription: "Enter the reason for canceling the auction that will be sent to all bidders",
  AuctionPage_ProviceReason: "Enter the reason ...",
  AuctionPage_CancelAuctionDialogErrorMessage: "The value cannot be empty",
  CopyThePhoneNumber: "Copy the phone number",
  SuccessfullyAddedPhoneNumberToTheClipboard: "Telephone number was successfully added to the clipboard",
  Notifications: "Notifications",
  PageNotFound: "The pages were not found",
  TheSiteDoesNotExistOrHasBeenRelocated: "The site does not exist or has been moved",
  ReturnToTheHomePage: "Return to the main page",
  Realized: "Realized",
  AddingNewAuctionBasedOnAuction: "Adding auction based on auction",
  InternalNote: "Internal note",
  WriteInternalNote: "Write an internal note",
  PhoneNumberWasntProvided: "The phone number was not provided",
  MySettings_Auctions: "Auctions",
  MySettings_Orders: "Transport orders",
  MySettings_System: "System",
  HtmlTemplatesManagement_Columns_Area: "Area",
  TodayAreEnding: "Today it ends",
  ForTodayYouHavePlaced: "At present, you are issued",
  DataFix: "Fix date",
  From: "From",
  To: "Down",
  DeleteAuction: "Remove the auction",
  InvitationToPlatform: "Invitation to the Platform",
  SuccessfullySendInvitationToPlatform: "An invitation to the Platform was successfully sent",
  AcceptRodo: "I accept the rules of the GDPR",
  ReadConditions: "I got acquainted with the conditions of loading, unloading and storage of cell concrete products H+H Polska",
  ReadPrinciples: "I got acquainted with the rules of safe loading of products to recipient cars",
  MyCompany: "My company",
  ClickHereToReadTheConditions: "Click here to read the conditions",
  ClickHereToReadThePrinciples: "Click here to read the rules",
  AddNew_Feminine: "Add a new one",
  AddNew_Masculine: "Add new",
  AddNewAuction: "Add new auctions",
  AddNewOrder: "Add a new order",
  AuctionHasBeenCanceledBy: "The auction was canceled by",
  InformationsAboutTheAuction: "Auction information",
  ActivateCompany: "Company activation",
  IsActive: "Or active",
  ReportCompany: "Report the company",
  TransportOrderDialog_Content: "Add attention to the order.",
  TransportOrderDialog_ErrorMessage: "The message cannot be empty.",
  TransportOrderDialog_ProvideMessage: "Write a message...",
  TransportOrderDialog_AddNote: "Add attention to the order",
  AttachNecessaryAttachmentsListedInFollowingList: "Attach the necessary attachments listed in the list below",
  Attachments: "Attachments",
  InProgressOfDateAndAcceptanceVerification: "During the process of data verification and acceptance",
  AfterPositiveConsiderationYouWillBeNotifiedByEmailAboutThePossibilityOfSubmittingOfferts: "After a positive consideration, you will be informed by email about the possibility of submitting offers",
  Bidded: "Has bid",
  LinkToTheAuction: "Link to the auction",
  InformationObligation: "Information obligation",
  ConsentToTheProcessingOfPersonalData: "Data processing agreement",
  Cancel: "Cancel",
  Save: "Save",
  AddCompany_AttachmentInformation_License: "Licenses for road transport in the field of mediation when transporting things",
  AddCompany_AttachmentInformation_Permission: "Permission to practice the carrier",
  AddCompany_AttachmentInformation_CarrierInsurance: "Carrier insurance",
  AddCompany_AttachmentInformation_ForwarderInsurance: "SPPSCEPORT OC insurance",
  SuccessfullyDownloadedAttachment: "An attachment was successfully downloaded",
  SuccessfullyRemovedAttachment: "An attachment was successfully removed",
  Automatically: "Automatically",
  AutomaticallyGenerated: "Automatically generated",
  AutomaticallyExposed: "Automatically issued",
  TransportOrderPage_CancelOrderDialogErrorMessage: "The value cannot be empty",
  LastMonth: "Last month",
  TransportOrderPage_CancelTransportOrderDialogDecription: "Enter the reason for canceling the order that will be sent to users",
  LastWeek: "Last week",
  TransportOrderPage_OrderCancelation: "Cancellation of the transport order",
  LastYear: "Last year",
  TransportOrderPage_ProviceReason: "Enter the reason",
  FieldRequired: "Required field",
  DriverPhoneNumber: "Driver's phone number",
  OnlyMyFacility: "Only my plant",
  AnotherCompany: "Another company",
  ImplementationDetails: "Details of notification",
  DeclaredLoadingRangeDateTime: "Declared range of date and time of loading",
  CurrentLoadingDateTime: "Current date and time of loading",
  LoadingDetails: "Loading details",
  DashboardActions: "Auction",
  CurrentlyYouHave: "Currently you have",
  RealizedInYears: "Realized in the years",
  ConfigurableDiagramDataModificationTitle: "Change the data range displayed on the chart",
  DownloadRaport: "Download the report",
  ClickToDisplayDataFromThisPeriod: "Click to view the data of this period",
  DeclaredUnloadingRangeDateTime: "Declared range of date and time of unloading",
  RealizedDetails: "Implementation details",
  Number: "Number",
  Code: "Code",
  TransportCode: "Transport code",
  Reports: "Reports",
  ExportNav: "EXPORT NAV",
  CreatedBy: "Created by",
  CreatedOn: "Date of creation",
  Generate: "Generate"
};
exports.default = _default;