"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _errors = _interopRequireDefault(require("./translations/errors.pl"));

var _errors2 = _interopRequireDefault(require("./translations/errors.en"));

var _errors3 = _interopRequireDefault(require("./translations/errors.de"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // File was autogenerated by tools/generateLabels.js script


var _default = {
  pl: _errors.default,
  en: _errors2.default,
  de: _errors3.default
};
exports.default = _default;