import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import lang from 'hh-shared/dist/language/services/languageService';
import loadingMethodsIds from 'common/loadingMethodsIds';
import auctionTypesIds from 'common/auctionTypesIds';
import BreadcrumbsContainer from 'hh-shared/dist/components/commons/BreadcrumbsContainer';
import FormPreviewSectionContainer from 'hh-shared/dist/components/layout/FormPreview/FormPreviewSectionContainer';
import FormCardWrapper from 'hh-shared/dist/components/layout/FormPreview/FormCardWrapper';
import cx from 'classnames';
import unloadingMethodsIds from 'common/unloadingMethodsIds';
import AuctionInformationAddForm from './Forms/AuctionInformationAddForm';
import AuctionsManagementSrv from './AuctionsManagementSrv';
import AuctionsManagementPagePreviewContainer from './AuctionsManagementPagePreviewContainer';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmitSave: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  showSubjects: PropTypes.bool.isRequired,
  auctionTypes: PropTypes.array.isRequired,
  loadingMethods: PropTypes.array.isRequired,
  unloadingMethods: PropTypes.array.isRequired,
  currentUserId: PropTypes.string.isRequired,
  onSubjectsChange: PropTypes.func.isRequired,
  onDistanceChange: PropTypes.func.isRequired,
  onPricePerKilometerChange: PropTypes.func.isRequired,
  onStartPriceChange: PropTypes.func.isRequired,
  onResetPrices: PropTypes.func.isRequired,
  onGetDefaultPrices: PropTypes.func.isRequired,
  routes: PropTypes.array.isRequired,
  zipCodes: PropTypes.array.isRequired,
};

const defaultProps = {};

class AuctionsManagementAddPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSpinner: false,
      facilities: [],
      currencies: [],
      auctionSubjects: [],
      principalPersons: [],
      addresses: [],
      initialValues: {},
    };
  }

  componentDidMount() {
    this.setState({ showSpinner: true });

    this.props.onGetDefaultPrices();

    Promise.all([
      AuctionsManagementSrv.getFacilitiesAsSelectItems(),
      AuctionsManagementSrv.getAuctionSubjectsAsSelectItems(),
      AuctionsManagementSrv.getPrincipalPersonsAsSelectItems(),
      AuctionsManagementSrv.getAddressesAsSelectItem(),
      AuctionsManagementSrv.getCurrenciesAsSelectItem(),
    ])
      .then(
        ([
          facilities,
          auctionSubjects,
          principalPersons,
          addresses,
          currencies,
        ]) => {
          this.setState({
            facilities,
            auctionSubjects,
            principalPersons,
            addresses,
            currencies,
            initialValues: {
              principalPerson: principalPersons.find(
                x => x.instanceId === this.props.currentUserId,
              ),
              auctionType: auctionTypesIds.publicId,
              capacity: 24000,
              loadingMethod: loadingMethodsIds.sideId,
              unloadingMethod: unloadingMethodsIds.sideId,
              currency: currencies.find(x => x.isDefault),
            },
          });
        },
      )
      .finally(() => this.setState({ showSpinner: false }));
  }

  render() {
    const {
      onSubmit,
      onSubmitSave,
      history,
      showSubjects,
      loadingMethods,
      unloadingMethods,
      auctionTypes,
      onSubjectsChange,
      onDistanceChange,
      onPricePerKilometerChange,
      onStartPriceChange,
      onResetPrices,
      routes,
      zipCodes,
    } = this.props;

    const {
      showSpinner,
      facilities,
      currencies,
      auctionSubjects,
      principalPersons,
      initialValues,
      addresses,
    } = this.state;

    return (
      <>
        <BreadcrumbsContainer
          links={[
            {
              routePath: '/',
              label: lang.labels.HomePage(),
            },
            {
              routePath: '/auctions-management',
              label: lang.labels.Auctions(),
            },
            {
              routePath: '/auctions-management/add',
              label: lang.labels.AddingNewAuction(),
            },
          ]}
        />
        <FormPreviewSectionContainer>
          <FormCardWrapper>
            <div className={cx({ fox_spinner: showSpinner })}>
              <AuctionInformationAddForm
                onSubmit={onSubmit}
                onSubmitSave={onSubmitSave}
                history={history}
                facilities={facilities}
                currencies={currencies}
                loadingMethods={loadingMethods}
                unloadingMethods={unloadingMethods}
                auctionTypes={auctionTypes}
                auctionSubjects={auctionSubjects}
                showSubjects={showSubjects}
                principalPersons={principalPersons}
                initialValues={initialValues}
                addresses={addresses}
                onSubjectsChange={onSubjectsChange}
                onDistanceChange={onDistanceChange}
                onPricePerKilometerChange={onPricePerKilometerChange}
                onStartPriceChange={onStartPriceChange}
                onResetPrices={onResetPrices}
                routes={routes}
                zipCodes={zipCodes}
              />
            </div>
          </FormCardWrapper>
          <AuctionsManagementPagePreviewContainer />
        </FormPreviewSectionContainer>
      </>
    );
  }
}

AuctionsManagementAddPage.propTypes = propTypes;
AuctionsManagementAddPage.defaultProps = defaultProps;

export default AuctionsManagementAddPage;
