import { yearTo, yearFrom } from 'dashboard/config';
import moment from 'moment';
import { configurableDiagramMonths as months } from 'common';

const now = moment();

const currentYear = now.year();

const getYearName = year => `in the ${year} year`;
const getMonthName = month => `in the ${month}`;
const getWeekName = week => `in the ${week} week`;

export const currentYearItem = {
  id: currentYear,
  name: getYearName(currentYear),
};

export const yearItems = [...Array(yearTo - yearFrom + 1).keys()].map(x => ({
  id: yearFrom + x,
  name: getYearName(yearFrom + x),
}));

const currentMonth = now.month();

export const currentMonthItem = {
  id: currentMonth,
  name: getMonthName(months[currentMonth]),
};

export const monthItems = [...months].map((month, index) => ({
  id: index,
  name: getMonthName(month),
}));

const currentWeek = now.isoWeek();

export const currentWeekItem = {
  id: currentWeek,
  name: getWeekName(currentWeek),
};

export const weeksItems = [...Array(moment().weeksInYear() + 1).keys()].map(
  x => ({
    id: x + 1,
    name: getWeekName(x + 1),
  }),
);
