/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import store from 'store/store';
import {
  getStaticDictionaries,
  getBigStaticDictionaries,
} from 'dictionary/actions';
import axios from 'config/axios';
import moment from 'moment';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './styles/style.scss';
import 'normalize.css/normalize.css';
import checkLoginValidity from './auth/loginValidity';
import signalRActionTypes from './hub-connection/actionTypes';
import resourceService from 'hh-shared/dist/language/services/resourceService';

resourceService.connect(store);

// eslint-disable-next-line react/no-render-return-value
const render = Component =>
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <Component />
      </Router>
    </Provider>,
    document.getElementById('root'),
  );

checkLoginValidity(store);
store.dispatch({ type: signalRActionTypes.CONNECT_TO_SIGNALR });
store.dispatch(getStaticDictionaries());
store.dispatch(getBigStaticDictionaries());
render(App);

function updateServerTime() {
  axios.get('time').then(x => {
    const offset = new Date(x.data.result).getTime() - Date.now();
    moment.now = function() {
      return offset + Date.now();
    };
  });
}

updateServerTime();

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('./App', () => {
      // eslint-disable-next-line global-require
      const NextApp = require('./App').default;
      render(NextApp);
    });
  }
} else {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [
      new Sentry.Integrations.GlobalHandlers({
        onerror: true,
        onunhandledrejection: true,
      }),
    ],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
