import actionTypes from './actionTypes';

const initialState = {
  static: {
    loadingMethods: [],
    unloadingMethods: [],
    auctionTypes: [],
    zipCodes: [],
    routes: [],
    zipCodeCodes: [],
  },
};

export default function dictionaryReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_STATIC_DICTIONARIES:
      return {
        ...state,
        static: {
          ...state.static,
          ...action.data,
        },
      };
    case actionTypes.STORE_BIG_STATIC_DICTIONARIES:
      return {
        ...state,
        static: {
          ...state.static,
          ...action.data,
          zipCodeCodes: action.data.zipCodes.map(x => x.code),
        },
      };
    default:
      return state;
  }
}
