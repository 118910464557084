"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0; // File was autogenerated by tools/generateLabels.js script

var _default = {
  AcceptForImplementation: "Notify",
  Add: "Hinzufügen",
  AddDriver: "Fügen Sie den Fahrer hinzu",
  AddNewDriver: "Fügen Sie einen neuen Fahrer hinzu",
  Back: "Zurückkehren",
  Cancel: "Stornieren",
  CloneAuction: "Doppelte Auktionen",
  CreateTransportOrder: "Erstellen Sie eine Bestellung",
  Delete: "Entfernen",
  Edit: "Bearbeiten",
  EditContent: "Inhalt bearbeiten",
  EditEmailContent: "E-Mail-Inhalt bearbeiten",
  EditSmsContent: "SMS -Inhalt bearbeiten",
  GetFromGus: "Laden Sie vom CSO herunter",
  GetPrice: "Laden Sie den Preis herunter",
  Loaded: "Geladen",
  LogIn: "Anmeldung",
  LogOut: "Ausloggen",
  Realized: "Erkannte",
  RemoveDriver: "Den Fahrer entfernen",
  Reset: "Zurücksetzen",
  ResetPassword: "Passwort zurücksetzen",
  Save: "Speichern",
  SaveAndPublish: "Speichern und veröffentlichen",
  SaveAsDraft: "Als einen Entwurf sichern",
  SaveAsNew: "Speichern Sie als neue Version",
  Search: "Suchen",
  SetNewPassword: "Legen Sie ein neues Passwort fest",
  Show: "Zeigen",
  Update: "Aktualisieren",
  UpdateAndPublish: "Aktualisieren und veröffentlichen",
  CancelOrder: "Bestellung stornieren",
  AddNewCar: "Fügen Sie ein neues Fahrzeug hinzu",
  RemoveCar: "Das Fahrzeug entfernen",
  SendInvitationToPlatform: "Senden Sie eine Einladung an die Plattform",
  SendApplicationForApproval: "Senden Sie eine Benachrichtigung zur Genehmigung",
  Send: "Senden",
  Download: "Herunterladen",
  RemoveCompanyAttachment: "Entfernen Sie den Anhang",
  UpdateImplementation: "Benachrichtigung aktualisieren",
  Generate: "Generieren"
};
exports.default = _default;