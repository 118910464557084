import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import toastrReducer from 'hh-shared/dist/toastr/toastrReducer';

import authReducer from 'auth/reducer';
import usersManagementReducer from 'users-management/reducer';
import htmlTemplatesManagementReducer from 'html-templates-management/reducer';
import notificationEditReducer from 'html-templates-management/EditPage/reducer';
import departmentsManagementReducer from 'departments-management/reducer';
import companiesManagementReducer from 'company-management/reducer';
import carTypesManagementReducer from 'car-types-management/reducer';
import carsManagementReducer from 'cars-management/reducer';
import facilitiesManagementReducer from 'facilities-management/reducer';
import routesManagementReducer from 'routes-management/reducer';
import currenciesManagementReducer from 'currencies-management/reducer';
import auctionsManagementReducer from 'auctions-management/reducer';
import auctionReducer from 'auction/reducer';
import transportOrdersManagementReducer from 'transport-orders-management/reducer';
import transportOrderPreviewPageReducer from 'transport-orders-management/TransportOrderPreviewPage/reducer';
import dictionaryReducer from 'dictionary/reducer';
import addressesManagementReducer from 'addresses-management/reducer';
import pricesManagementReducer from 'prices-management/reducer';
import defaultPricesManagementReducer from 'default-prices-management/reducer';
import defaultPricesReducer from 'auctions-management/defaultPricesReducer';
import sideMenuReducer from 'layout/Menu/sideMenuReducer';
import zipCodesReducer from 'zip-codes-management/reducer';
import dashboardReducer from 'dashboard/reducer';
import userPreferencesReducer from '../user-preferences/reducer';
import languageReducer from 'hh-shared/dist/language/reducer';

export default combineReducers({
  form: formReducer,
  auth: authReducer,
  toastr: toastrReducer,
  dictionary: dictionaryReducer,
  sideMenu: sideMenuReducer,
  usersManagement: usersManagementReducer,
  htmlTemplatesManagement: htmlTemplatesManagementReducer,
  departmentsManagement: departmentsManagementReducer,
  companiesManagement: companiesManagementReducer,
  carTypesManagement: carTypesManagementReducer,
  carsManagement: carsManagementReducer,
  facilitiesManagement: facilitiesManagementReducer,
  routesManagement: routesManagementReducer,
  currenciesManagement: currenciesManagementReducer,
  auctionsManagement: auctionsManagementReducer,
  transportOrdersManagement: transportOrdersManagementReducer,
  transportOrderPreviewPage: transportOrderPreviewPageReducer,
  addressesManagement: addressesManagementReducer,
  pricesManagement: pricesManagementReducer,
  notificationEdit: notificationEditReducer,
  defaultPricesManagement: defaultPricesManagementReducer,
  defaultPrices: defaultPricesReducer,
  zipCodesManagement: zipCodesReducer,
  auction: auctionReducer,
  dashboard: dashboardReducer,
  userPreferences: userPreferencesReducer,
  language: languageReducer,
});
