import {
  isDateFromTo,
  areNotEquals,
  isRequiredArray,
  isRequired,
} from 'fox-react/dist/utils/formValidators';
import fieldNames from 'common/fieldNames';
import lang from 'hh-shared/dist/language/services/languageService';

const fnames = fieldNames.transportOrderInformationForm;

export const validate = values => {
  const errors = {};

  isRequiredArray(
    errors,
    values,
    [
      fnames.facility,
      fnames.company,
      fnames.loadingTime,
      fnames.unloadingTime,
      fnames.distance,
      fnames.pricePerKilometer,
      fnames.value,
      fnames.capacity,
      fnames.loadingMethodId,
      fnames.unloadingMethodId,
      fnames.currency,
      fnames.loadingTimeTo,
      fnames.unloadingTimeTo,
    ],
    lang.validationMessages.FieldRequired(),
  );

  if (!values.loadingTimeFix) {
    isRequired(
      errors,
      values,
      fnames.loadingTimeFrom,
      lang.validationMessages.FieldRequired(),
    );
  }

  if (!values.unloadingTimeFix) {
    isRequired(
      errors,
      values,
      fnames.unloadingTimeFrom,
      lang.validationMessages.FieldRequired(),
    );
  }

  if (values.isNewCar) {
    isRequired(
      errors,
      values,
      'newCar.registerNumber',
      lang.validationMessages.FieldRequired(),
    );
  }

  if (!values.isNewLoadingAddress) {
    isRequired(
      errors,
      values,
      fnames.loadingAddress,
      lang.validationMessages.FieldRequired(),
    );
  } else {
    isRequiredArray(
      errors,
      values,
      [
        'newLoadingAddress.zipCode',
        'newLoadingAddress.city',
        'newLoadingAddress.street',
      ],
      lang.validationMessages.FieldRequired(),
    );
  }

  if (!values.isNewUnloadingAddress) {
    isRequired(
      errors,
      values,
      fnames.unloadingAddress,
      lang.validationMessages.FieldRequired(),
    );
  } else {
    isRequiredArray(
      errors,
      values,
      [
        'newUnloadingAddress.zipCode',
        'newUnloadingAddress.city',
        'newUnloadingAddress.street',
      ],
      lang.validationMessages.FieldRequired(),
    );
  }

  if (!values.isNewUnloadingAddress && !values.isNewLoadingAddress) {
    areNotEquals(
      errors,
      values,
      [fnames.unloadingAddress, fnames.loadingAddress],
      lang.validationMessages.LoadingUnloadingAddressesCannotBeTheSame(),
    );
  }

  isDateFromTo(
    errors,
    values,
    fnames.loadingTimeTo,
    fnames.unloadingTimeTo,
    lang.validationMessages.LoadingTimeCannotBeLaterThanUnloadingTime(),
  );

  return errors;
};
